<template>
  <div>
    <v-progress-linear indeterminate v-if="LoadingSlots" ></v-progress-linear>
    <v-container fluid v-if="!LoadingSlots && AvailableSlots">
      <v-row dense>
        <v-col v-for="date in AvailableDates" :key="date.key" :style="{
          'flex': '0 0 ' + (100 / AvailableDates.length) + '%',
          'max-width': (100 / AvailableDates.length) + '%'
        }">
          <div class="elevation-0 mb-2 text-center amber darken-2 white--text py-1 rounded"
               :class="{ 'py-2': $vuetify.breakpoint.smAndUp}">

              <div class="poppins-semibold" style="font-size: 1.0rem">
                {{ date.dayName }}
              </div>
              <div class="poppins-regular truncate">
                {{ date.dayMonth }}
              </div>
          </div>

          <div v-for="item in AvailableSlots[date.key]" :key="item" >
            <div
              class="poppins-regular SelectableHour pa-1 rounded"
              @click="SelectSlot(date.key, item)"
              :class="{ Active: item === SelectedTime && date.key === SelectedDate }"
            >
              {{ item }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="SelectedDate && SelectedTime" class="pa-3 ">
      <div class="text-center mb-3 amber lighten-3 grey--text text--darken-3 py-2 rounded poppins-regular" style="font-size: 0.8rem">
        Stai per prenotare:
        <br/>
        <span class="poppins-semibold" style="font-size: 1rem">
        Incontro Conoscitivo
      </span>
        <br/>
        <span class="poppins-semibold" style="font-size: 0.8rem">
        {{ formattedSelectedDateTime }}
      </span>
      </div>
      <div>
        <v-btn
          block
          dark
          large
          color="amber darken-2"
          class="text-capitalize"
          @click="CreateIntroductionVisitFromCalendar"
          :disabled="!SelectedDate || !SelectedTime"
          :loading="CreatingBooking"
        >
          <v-icon left>fas fa-calendar-alt</v-icon>

          Prenota Ora
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import CrudClient from "@/services/CrudClient";
import { mapActions } from "vuex";
import moment from "moment";

export default {
	name: "CreateEventFromCalendar",
	data() {
		return {
			AvailableSlots: null,
			LoadingSlots: false,
			CreatingBooking: false,
			SelectedDate: null,
			SelectedTime: null
		}
	},

	computed: {
		AvailableDates() {
			if (!this.AvailableSlots) { return null; }
			// return keys of AvailableSlots
			return Object.keys(this.AvailableSlots).map(key => ({
				key: moment(key).format("YYYY-MM-DD"),
				dayName: moment(key).format("ddd"),
				dayMonth: moment(key).format("D MMM")
			}));
		},

		formattedSelectedDateTime() {
			const m = moment(this.SelectedDate + "T" + this.SelectedTime + ":00");
			return m.format("dddd D MMMM YYYY ") + "alle ore " + m.format("HH:mm");
		}
	},

	created() {
		this.AvailableSlotsService = new CrudClient("CurrentPatient/AvailableSlots");
		this.BookingsService = new CrudClient("CurrentPatient/Bookings");
	},
	async mounted() {
		await this.LoadAvailableSlots();

		moment.locale("it");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		// Function to group, sort, and include the hour component

		SelectSlot(date, hour) {
			this.SelectedDate = date;
			this.SelectedTime = hour;
		},
		isResultEmpty(obj) {
			// Filter out Vue's default reactive property (__ob__) and check for other keys
			return Object.keys(obj).filter(key => key !== "__ob__").length === 0;
		},
		async LoadAvailableSlots() {
			try {
				this.LoadingSlots = true;
				const res = await this.AvailableSlotsService.Get(null, "GetSlotsForIntroductionVisit");
				if (this.isResultEmpty(res)) {
					this.$emit("noAvailableSlots");
					return;
				}
				this.AvailableSlots = res;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingSlots = false;
			}
		},

		async CreateIntroductionVisitFromCalendar() {
			const dto = {
				SelectedDate: this.SelectedDate,
				SelectedTime: this.SelectedTime
			};

			try {
				this.CreatingBooking = true;
				const booking = await this.BookingsService.Post(null, dto, "CreateIntroductionVisitFromCalendarSlot", true);
				this.$log.debug("Booking created by user");
				this.$emit("bookingCreated", booking.BookingId);
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotCreateBooking") });
				this.$captureError(error);
			} finally {
				this.CreatingBooking = false;
			}
		}
	}
}
</script>

<style scoped lang="scss">
@import "@/assets/css/variables.scss";

$amberLighten3: #FFE082;
$amberDarken1: #FFB300;
$amberDarken2: #FFA000;

.SelectableHour{
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  background: white;
  border: 1px solid $amberLighten3;
  font-size: 1rem;

  @media (max-width: 599px) {
    margin-bottom: 5px;
    font-size: 0.85rem;
  }

  &:hover{
    background:$amberLighten3;
    color: black;
  }

  &.Active {
    //background-color: #699b30 !important;
    //border-color: #699b30 !important;
    background-color: $amberDarken2 !important; //amber darken-1
    border-color: $amberDarken2 !important;
    color: white;
  }
}
</style>
