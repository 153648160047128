<script>
export default {
	name: "TermsAndConditionsGoogleCalendar.vue"
}
</script>

<template>
  <v-card max-width="1000" class="mx-auto">
    <v-card-text class="privacy">
      <h1 class="nutriyou-h2 primary--text text-center">
        Terms and Conditions for Using Google Calendar Integration
      </h1>

      <p>Data di entrata in vigore: 25/12/2025</p>
      <p>
        Questi Termini e Condizioni (“Termini”) regolano l’utilizzo
        dell’integrazione con Google Calendar fornita da Nutriyou SRL (“noi,” “nostro” o “ci”).
        Collegando il tuo Google Calendar alla nostra piattaforma, accetti questi Termini,
        che si aggiungono ai termini e alle condizioni applicabili all'accordo con Nutriyou SRL
        come nutrizionista partner.
      </p>

      <h2>1. Idoneità</h2>
      <ul>
        <li>
          L'integrazione con Google Calendar è disponibile esclusivamente per i nutrizionisti
          partner di Nutriyou SRL.
        </li>
        <li>I pazienti e altri utenti non sono idonei ad utilizzare questa integrazione.</li>
      </ul>

      <h2>2. Scopo dell'Integrazione</h2>
      <ul>
        <li>
          L'integrazione con Google Calendar è fornita per aiutarti a gestire gli appuntamenti
          e sincronizzare la disponibilità con la piattaforma di Nutriyou SRL.
        </li>
        <li>
          Questa funzionalità ha lo scopo di migliorare l'efficienza e prevenire conflitti
          di programmazione bloccando gli slot di tempo non disponibili.
        </li>
      </ul>

      <h2>3. Accesso e Utilizzo dei Dati</h2>
      <ul>
        <li>
          Collegando il tuo Google Calendar, ci autorizzi ad accedere ai dati specifici del
          calendario esclusivamente per i seguenti scopi:
        </li>
        <li class="ql-indent-1">Sincronizzare i tuoi appuntamenti.</li>
        <li class="ql-indent-1">Visualizzare gli slot di tempo disponibili e non disponibili.</li>
        <li class="ql-indent-1">Prevenire le doppie prenotazioni.</li>
        <li>
          Non accediamo, utilizziamo o condividiamo i contenuti dei tuoi eventi al di fuori
          di quanto necessario per questi scopi.
        </li>
      </ul>

      <h2>4. Privacy e Sicurezza dei Dati</h2>
      <ul>
        <li>
          I dettagli dei tuoi eventi su Google Calendar, come titolo, descrizione e partecipanti,
          rimangono privati e non sono visibili ad altri utenti, al nostro personale o ai pazienti.
        </li>
        <li>
          Non archiviamo gli eventi del tuo Google Calendar sui nostri server. I dati vengono
          recuperati in tempo reale solo quando necessario per l'integrazione.
        </li>
        <li>
          Per maggiori dettagli, consulta la nostra
          <a href="./PrivacyGoogleCalendar" target="_blank">Informativa sulla Privacy</a>.
        </li>
      </ul>

      <h2>5. Responsabilità dell'Utente</h2>
      <p>In qualità di utente dell'integrazione con Google Calendar, accetti di:</p>
      <ul>
        <li>
          Assicurarti che il calendario collegato alla nostra piattaforma sia il tuo e venga
          utilizzato esclusivamente per scopi professionali.
        </li>
        <li>Mantenere sicure le tue credenziali di Google e non condividerle con parti non autorizzate.</li>
        <li>
          Notificarci immediatamente se sospetti un accesso non autorizzato o un uso
          improprio dei dati del tuo Google Calendar.
        </li>
      </ul>

      <h2>6. Limitazioni di Responsabilità</h2>
      <ul>
        <li>
          Nutriyou SRL non è responsabile per eventuali errori di programmazione, appuntamenti
          mancati o conflitti derivanti da un uso improprio o da problemi di integrazione.
        </li>
        <li>
          Sebbene adottiamo misure di sicurezza robuste, non siamo responsabili per l'accesso
          non autorizzato al tuo account Google causato da fattori esterni fuori dal nostro controllo.
        </li>
      </ul>

      <h2>7. Termination of Integration</h2>
      <ul>
        <li>
          Puoi revocare l'accesso al tuo Google Calendar in qualsiasi momento tramite le impostazioni
          del tuo account Google.
        </li>
        <li>
          Ci riserviamo il diritto di sospendere o terminare il tuo accesso all'integrazione
          se violi questi Termini o i nostri termini e condizioni principali.
        </li>
      </ul>

      <h2>8. Modifiche ai Termini</h2>
      <ul>
        <li>
          Ci riserviamo il diritto di modificare questi Termini in qualsiasi momento. Le modifiche
          saranno comunicate tramite aggiornamenti sul nostro sito web o sulla piattaforma. L'utilizzo
          continuato dell'integrazione dopo tali aggiornamenti costituisce accettazione dei Termini
          modificati.
        </li>
      </ul>

      <h2>9. Legge Applicabile</h2>
      <ul>
        <li>Questi Termini sono regolati e interpretati in conformità con le leggi di [Inserire Giurisdizione].</li>
      </ul>

      <h2>10. Contatti</h2>
      <p>
        Per qualsiasi domanda o chiarimento riguardante questi Termini o l'integrazione con
        Google Calendar, ti preghiamo di contattarci a:
      </p>
      <ul>
        <li>Email: info@nutriyou.it</li>

      </ul>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.privacy{
  h2{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  p{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  ul{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
