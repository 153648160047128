<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
      </template>
		</v-breadcrumbs>

		<h1 class="nutriyou-h1">{{ $t("pageTitle") }}</h1>

		<skeleton-table v-if="LoadingData" :columns="4" :actions="1"></skeleton-table>

		<v-alert v-if="!LoadingData && items.length === 0" color="info" dark>
			{{ $t("NoPatients") }}
		</v-alert>
		<TallyPatientFormDialog
			v-model="showTallyForm"
			:UserId="tallyFormUserId"
		></TallyPatientFormDialog>
		<CreateBookingDialog
			v-model="showCreateBookingDialog"
			:PatientUserId="createBookingPatientId"
			@bookingCreated="OnBookingCreated"
		>
		</CreateBookingDialog>
<v-container fluid>
	<v-row>
		<v-col cols="6" offset="0" md="3" >
			<PatientPicker v-model="selectedPatientUserId" @change="LoadPatients(true)"></PatientPicker>
		</v-col>
		<v-col cols="6" offset="0" offset-md="3" md="3" >
				<v-select v-model="filterByArchiveStatus" :items="filterByArchiveStatusItems" item-text="Name" item-value="Value"
								@change="LoadPatients(true)" label="Filtra per stato cliente">
							</v-select>
			</v-col>
		<v-col cols="6" md="3">
			<v-select v-model="orderBy" :items="orderByItems" item-text="Name" item-value="Value"
							@change="LoadPatients(true)" label="Ordina">
						</v-select>
		</v-col>
	</v-row>
</v-container>

		<v-card class="my-2" v-for="patient in items" :key="patient.UserId">
			<v-container fluid>
				<v-row>
					<v-col cols="12" md="8">
						<v-chip
							label
							small
							dark
							v-if="patient.Bookings.length === 0"
							color="green lighten-1 mb-5"
							>Nuovo Paziente</v-chip
						>
						<div>
							<UserAvatarFullname
								style="display: inline-block"
								:showEmailIdentifier="true"
								:profile="patient.ApplicationUser"
							></UserAvatarFullname>
							<v-icon v-if="patient.IsArchived" small class="ml-2" color="grey darken-1">fas fa-inbox-in</v-icon>
							<br v-if="$vuetify.breakpoint.xs" />
							<v-btn
								color="primary"
								class="text-capitalize"
								:class="{ 'ml-4': !$vuetify.breakpoint.xs }"
								:to="'/Chat?UserId=' + patient.UserId"
							>
								<v-icon left>fas fa-comment-alt-lines</v-icon>Chat</v-btn
							>
						</div>
					</v-col>
					<v-col cols="12" md="4" class="grey--text text-right">Data di creazione {{ patient.CreateDate | formatDate }}</v-col>
				</v-row>
			</v-container>
			<v-card-actions>

				<v-btn
					color="primary"
					class="text-capitalize"
					@click="showBookingDialog(patient.UserId)"
				>
					<v-icon left>fas fa-plus</v-icon>
					<span v-if="$vuetify.breakpoint.mdAndUp">Nuovo </span>Appuntamento</v-btn
				>
				<v-spacer></v-spacer>

				<v-btn v-if="$vuetify.breakpoint.mdAndUp"
					color="info"
					@click="showTallyPatientForm(patient.UserId)"
					class="text-capitalize"
				>
					<v-icon left>fas fa-info-circle</v-icon>Info del paziente</v-btn
				>
				<v-btn v-else icon color="info"
						@click="showTallyPatientForm(patient.UserId)">
					<v-icon left>fas fa-info-circle</v-icon>
				</v-btn>

				<v-btn
				v-if="$vuetify.breakpoint.mdAndUp"
					color="secondary"
					:to="'./Bookings/Patient/' + patient.UserId"
					:disabled="patient.Bookings.length === 0"
					class="text-capitalize"
				>
					<v-icon left>fas fa-calendar-alt</v-icon>
					{{ $t("nutriyou.bookings") }}

					<span v-if="patient.Bookings.length > 0" class="ml-2"
						>({{ patient.Bookings.length }})</span
					>
				</v-btn>
				<template v-else>
				<v-btn  icon color="secondary"
						:to="'./Bookings/Patient/' + patient.UserId"
						:disabled="patient.Bookings.length === 0">
						<v-icon left>fas fa-calendar-alt</v-icon>
					</v-btn>
					<span class="font-weight-bold text-secondary" v-if="patient.Bookings.length > 0"
							>({{ patient.Bookings.length }})</span
						>
				</template>
			</v-card-actions>
		</v-card>

		<div class="text-center">
			<v-pagination
				v-if="itemsPaging && items.length > 0"
				v-model="currentPage"
				:length="itemsPaging.TotalPages"
				:total-visible="5"
			></v-pagination>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import UserAvatarFullname from "@/components/Shared/UI/UserAvatarFullname";
import PatientPicker from "./components/PatientPicker";
import TallyPatientFormDialog from "@/views/Nutritionists/components/TallyPatientFormDialog";

import CreateBookingDialog from "@/views/Nutritionists/components/CreateBookingDialog";
export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	components: {
		UserAvatarFullname,
		TallyPatientFormDialog,
		CreateBookingDialog,
		PatientPicker
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			LoadingData: false,
			items: [],
			itemsPaging: null,
			currentPage: 1,
			pageSize: 10,
			showTallyForm: false,
			tallyFormUserId: null,
			showCreateBookingDialog: false,
			createBookingPatientId: null,
			orderBy: "CreateDate:desc",
			orderByItems: [
				{ Name: "Più recenti", Value: "CreateDate:desc" },
				{ Name: "Meno recenti", Value: "CreateDate" },
			],
			filterByArchiveStatus: 0,
			filterByArchiveStatusItems: [
				{ Name: "Attivi", Value: 0 },
				{ Name: "Archiviati", Value: 1 },
				{ Name: "Tutti", Value: null },
			],
			selectedPatientUserId: null

			// <v-select v-model="filterByArchiveStatus" :items="filterByArchiveStatusItems" item-text="Name" item-value="Value"
		};
	},
	watch: {
		showTallyForm: function (val) {
			if (!val) {
				this.tallyFormUserId = null;
			}
		},
		async currentPage() {
			await this.LoadPatients();
		},
	},
	created() {
		this.PatientsService = new CrudClient("CurrentNutritionist/Patients");
	},

	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.areaNutritionists"),
			disabled: false,
			exact: true,
			to: "/AreaNutritionists",
		});

		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/AreaNutritionists/Patients",
		});

		await this.LoadPatients();
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadPatients(resetPaging) {
			if (resetPaging) {
				this.currentPage = 1;
			}

			let filter = null;

			if (this.selectedPatientUserId != null) {
				if (filter) filter += ";";
				else filter = "";

				filter += "UserId:" + this.selectedPatientUserId
			} else {
				if (this.filterByArchiveStatus != null) {
					if (filter) filter += ";";
					else filter = "";

					filter = "IsArchived:" + (this.filterByArchiveStatus ? "true" : "false");
				}
			}

			try {
				this.LoadingData = true;
				const res = await this.PatientsService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "*, Bookings.*",
					filter: filter,
					orderBy: this.orderBy
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async OnBookingCreated() {
			this.showCreateBookingDialog = false;
			await this.LoadPatients();
		},

		showTallyPatientForm(userId) {
			this.showTallyForm = true;
			this.tallyFormUserId = userId;
		},
		showBookingDialog(userId) {
			this.showCreateBookingDialog = true;
			this.createBookingPatientId = userId;
		},
	},
};
</script>
<i18n>
{
	"it": {
		"pageTitle": "I Miei Pazienti",
		"pageMetaDescription": "-",
		"NoPatients": "Non hai ancora pazienti"
	}
}
</i18n>
