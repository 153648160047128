<template>
  <div style="height: 100%">
    <iframe
      :title="title || 'Video calling component'"
      ref="iframe"
      :src="iframeSrc"
      style="border: none; height: 100%; width: 100%"
      allow="autoplay; camera; microphone; fullscreen; speaker; display-capture; media-capture"
    ></iframe>
  </div>
</template>

<script>
export default {
	name: "WherebyEmbed",
	props: {
		room: {
			type: String,
			default: null,
			required: true
			// A room URL
			// The full URL of the room you want to embed (required)
		},
		subdomain: {
			type: String,
			default: null,
		},
		title: {
			type: String,
			default: "Video calling component",
		},

		aec: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Turn off acoustic echo cancellation on audio
		},
		agc: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Turn off acoustic echo cancellation on audio
		},
		audio: {
			type: [Boolean, String],
			default: null,
			// "off"
			// Enter meeting with audio off
		},
		audioDenoiser: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Enables/Disables the noise cancelation feature
		},
		autoHideSelfView: {
			type: [Boolean, String],
			default: null,
			// Nothing (off) or "on"
			// Automatically hide the self view in the bottom right
		},
		autoSpotlight: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Automatically spotlight the local "host" on room join
		},
		avatarUrl: {
			type: String,
			default: null,
			// Image URL - Must be HTTPS and cannot contain query params
			// Set the avatar picture of the participant. The image must be a .png or .jpeg, and a square maximum of 64x64.
		},
		background: {
			type: [Boolean, String],
			default: null,
			// "off"
			// Render without background to let embedding app render its own
		},
		bottomToolbar: {
			type: [Boolean, String],
			default: null,
			// "off"
			// Hide the entire bottom tool bar. Useful if creating buttons using commands
		},
		breakout: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Show/hide the Breakout Groups feature for the meeting host.
		},
		cameraAccess: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Disable camera access for the local participant, allowing for only audio input
		},
		cameraEffect: {
			type: String,
			default: null,
			// slight-blur, blur, image-cabin, image-concrete, image-brick, image-sunrise, image-day, image-night
			// Set the default camera effect to be used.
		},
		chat: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Enable/disable chat
		},
		callQualityMonitoring: {
			type: [Boolean, String],
			default: null,
			// Nothing (off) or "on"
			// Enable/disable Whereby's call quality monitoring interface
		},
		displayName: {
			type: String,
			default: null,
			// 100 character string - Excluding $!<>:;
			// Set displayname of participant
		},
		externalId: {
			type: String,
			default: null,
			// Up to 36 alphanumeric characters (details)
			// Custom identifier for the participant. Can be searched in insights endpoints
		},
		floatSelf: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Float the self view to the bottom right
		},
		groups: {
			type: String,
			default: null,
			// "Group Name(s)" - EX: "orange, banana, coconut"
			// Predefine up to 20 groups for the breakout groups function.
		},
		lang: {
			type: String,
			default: null,
			// Nothing (en) or fr | it | de | nb | da | nl | pt | pl | es | hi | cs | zh-hant | jp
			// Set the meeting UI language to match your product or service.
		},
		leaveButton: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Enable/disable the leave button
		},
		localization: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Show/hide the language picker
		},
		locking: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Show/hide the room lock button and settings toggle
		},
		logo: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Show/hide the logo in the room header
		},
		lowData: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Use a lower resolution by default
		},
		metadata: {
			type: String,
			default: null,
			// <string>
			// <string> gets passed on to the corresponding webhooks
		},
		minimal: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Apply minimal UI for embed scenarios
		},
		moreButton: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Show/hide the “…” button
		},
		participantCount: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Show/hide the participant counter
		},
		people: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Show/hide the participant list
		},
		pipButton: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Show/hide the Picture in Picture button
		},
		precallCeremony: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Determines if users see the pre-call device and connectivity test
		},
		precallCeremonyCanSkip: {
			type: [Boolean, String],
			default: null,
			// Nothing (off) or "on"
			// Adds functionality for participants to skip the connectivity test
		},
		precallPermissionHelpLink: {
			type: String,
			default: null,
			// <url>
			// Specify custom help link in pre-call review step pointing users to additional support pages
		},

		roomIntegrations: {
			type: [Boolean, String],
			default: null,
			// Nothing (off) or "on"
			// Enables YouTube and Miro integrations in the meeting
		},
		screenshare: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Enable/disable screenshare
		},
		skipMediaPermissionPrompt: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Skips request permissions UI and asks for devices directly
		},
		subgridLabels: {
			type: [Boolean, String],
			default: null,
			// Nothing (off) or "on"
			// Show/hide name labels in the subgrid
		},
		timer: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Show/hide the meeting timer
		},
		toolbarDarkText: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Sets button icon labels color to black
		},
		topToolbar: {
			type: [Boolean, String],
			default: null,
			// Nothing (on) or "off"
			// Used to toggle the entire top toolbar on/off.
		},
		video: {
			type: [Boolean, String],
			default: null,
			// "off"
			// Enter meeting with video off
		},
	},
	data() {
		return {
			iframeSrc: "",
			roomUrl: null,
		};
	},
	methods: {
		parseRoomUrlAndSubdomain(roomAttribute, subdomainAttribute) {
			if (!roomAttribute) {
				throw new Error("Missing room attribute");
			}
			const m = /https:\/\/([^.]+)(\.whereby\.com|-ip-\d+-\d+-\d+-\d+.hereby.dev:4443)\/.+/.exec(
				roomAttribute
			);
			const subdomain = (m && m[1]) || subdomainAttribute;
			if (!subdomain) {
				throw new Error("Missing subdomain attribute");
			}
			if (!m) {
				throw new Error("Could not parse room URL");
			}
			const roomUrl = new URL(roomAttribute);
			return {
				subdomain,
				roomUrl,
			};
		},
		buildIframeSrc() {
			try {
				const { roomUrl, subdomain } = this.parseRoomUrlAndSubdomain(
					this.room,
					this.subdomain
				);

				const params = {
					jsApi: true,
					we: "1",
					iframeSource: subdomain,
					minimal: this.minimal,
				};

				Object.keys(this.$props)
					.filter((key) => this[key] !== null)
					.forEach((key) => {
						params[key] = this[key];
					});

				Object.entries(params).forEach(([key, value]) => {
					if (!roomUrl.searchParams.has(key)) {
						roomUrl.searchParams.set(key, value);
					}
				});

				this.roomUrl = roomUrl;
				this.iframeSrc = roomUrl.toString();
			} catch (error) {
				console.error("Error building iframe source:", error.message || error);
				this.iframeSrc = ""; // Clears iframe in case of error
			}
		},
		postCommand(command, args = []) {
			if (this.$refs.iframe) {
				this.$refs.iframe.contentWindow.postMessage(
					{ command, args },
					this.roomUrl.origin
				);
			}
		},
		// Public methods to communicate with the iframe
		endMeeting() {
			this.postCommand("end_meeting");
		},
		knock() {
			this.postCommand("knock");
		},
		leaveRoom() {
			this.postCommand("leave_room");
		},
		openSettings(settingsPane = "media") {
			this.postCommand("open_settings", [settingsPane]);
		},
		startRecording() {
			this.postCommand("start_recording");
		},
		stopRecording() {
			this.postCommand("stop_recording");
		},
		handleMessage(event) {
			if (!this.roomUrl || event.origin !== this.roomUrl.origin) return;
			const { type, payload } = event.data;
			this.$emit(type, payload);
		}
	},
	watch: {
		$props: {
			handler: function () {
				// Call your desired method whenever any prop changes
				this.buildIframeSrc();
			},
			// deep: true, // Ensures nested prop objects are also observed
		},
	},
	mounted() {
		this.$log.debug("VueWhereby mounted");
		this.buildIframeSrc();
		window.addEventListener("message", this.handleMessage);
	},
	destroyed() {
		window.removeEventListener("message", this.handleMessage);
	}
};
</script>
