<template>
<div class="whereby-container">
  <v-skeleton-loader  v-if="loadingBookingDetails" type="card" class="ma-4">

  </v-skeleton-loader>
  <div v-if="!loadingBookingDetails">
    <v-alert type="error" v-if="!MeetingUrl" class="ma-4">
      Impossibile caricare la videochiamata.
      <br />
      Contattare assistenza clienti
    </v-alert>
  </div>
    <VueWhereby v-if="!loadingBookingDetails && MeetingUrl"
                style="height: 100%;width: 100%"
                :room="MeetingUrl"
                :display-name="UserProfile.DisplayName"
                lang="it"
                logo="off"
                background="off"
                participantCount="off"
    />

</div>
</template>
<script>
import VueWhereby from "@/components/Whereby/VueWhereby.vue";
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient";
export default {
	name: "VideoRoom",
	components: { VueWhereby },
	data: function() {
		return {
			loadingBookingDetails: false,
			MeetingUrl: null
			// HostRoomUrl: "https://nutriyou-italia.whereby.com/demo-6c01664a-a88e-486a-be57-6fa4d2400c95?roomKey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWV0aW5nSWQiOiI5NTMyMzQ4NCIsInJvb21SZWZlcmVuY2UiOnsicm9vbU5hbWUiOiIvZGVtby02YzAxNjY0YS1hODhlLTQ4NmEtYmU1Ny02ZmE0ZDI0MDBjOTUiLCJvcmdhbml6YXRpb25JZCI6IjMwNDE2NiJ9LCJpc3MiOiJodHRwczovL2FjY291bnRzLnNydi53aGVyZWJ5LmNvbSIsImlhdCI6MTczNjY3NzI1Niwicm9vbUtleVR5cGUiOiJtZWV0aW5nSG9zdCJ9.eUZ8HWS_vdhmTHEIZBYBwo1wxPeQT-TFU5rGZKyprc8",
			// ViewerRoomUrl: "https://nutriyou-italia.whereby.com/demo-6c01664a-a88e-486a-be57-6fa4d2400c95?roomKey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tUmVmZXJlbmNlIjp7InJvb21OYW1lIjoiL2RlbW8tNmMwMTY2NGEtYTg4ZS00ODZhLWJlNTctNmZhNGQyNDAwYzk1Iiwib3JnYW5pemF0aW9uSWQiOiIzMDQxNjYifSwiaXNzIjoiaHR0cHM6Ly9hY2NvdW50cy5zcnYud2hlcmVieS5jb20iLCJpYXQiOjE3MzY2NzcyNTYsInJvb21LZXlUeXBlIjoidmVyaWZpZWRWaWV3ZXIifQ.f-EVhY8PsmOeKT6NBv2807B7GVkPWQM9MSrzwfveDPw"
		};
	},
	computed: {
		...mapGetters([
			"UserProfile",
			"isUserInRole"
		]),
	},
	created() {
		this.NutritionistBookingsService = new CrudClient("CurrentNutritionist/Bookings");
		this.CurrentPatientBookingsService = new CrudClient("CurrentPatient/Bookings");
	},
	async mounted() {
		try {
			this.loadingBookingDetails = true;

			let meetingDetails = null;
			if (this.isUserInRole("Patient")) {
				meetingDetails = await this.CurrentPatientBookingsService.GetSelectedFields(this.$route.params.BookingId, "BookingId, WherebyMeeting.RoomUrl");
				this.MeetingUrl = meetingDetails.WherebyMeeting?.RoomUrl;
			} else if (this.isUserInRole("Nutritionist")) {
				meetingDetails = await this.NutritionistBookingsService.GetSelectedFields(this.$route.params.BookingId, "BookingId, WherebyMeeting.HostRoomUrl");
				this.MeetingUrl = meetingDetails.WherebyMeeting?.HostRoomUrl;
			}
		} catch (error) {
			this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			this.$captureError(error);
		} finally {
			this.loadingBookingDetails = false;
		}

		// it is used to hide footer
		this.SetChatWindowState(true);
		this.setActualVHProperty();
		window.addEventListener("resize", this.setActualVHProperty);
		// window.addEventListener("beforeunload", this.handleBeforeUnload);
	},
	beforeDestroy() {
		this.SetChatWindowState(false);
		window.removeEventListener("resize", this.setActualVHProperty);
		// window.removeEventListener("beforeunload", this.handleBeforeUnload);
	},
	methods: {
		...mapActions([
			"snackError",
			"SetChatWindowState"]),

		setActualVHProperty() {
			// First we get the viewport height; we multiply it by 1% to get a value for a vh unit
			const vh = window.innerHeight * 0.01;
			// Then we set the value in the --vh custom property to the root of the document
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		},

		// handleBeforeUnload(event) {
		// 	event.preventDefault();
		// 	event.returnValue = ""; // Triggers the browser's confirmation dialog
		// },
	}
}
</script>

<style scoped lang="scss">
@import "@/assets/css/variables";
/* Ensures the parent container spans the entire screen */
$margin: 15px;
$headerHeight: 56px + 2*$margin;

.whereby-container {

  height: calc(100vh - #{$headerHeight});

  @media #{map-get($display-breakpoints, 'xs-only')} {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: calc(100vh - #{$headerHeight});
    height: calc(#{"var(--vh, 1vh) * 100"} - #{$headerHeight});
    //margin: -12px;
    // no longer needed if app.vue container :class="{ 'pa-0': $vuetify.breakpoint.xsOnly}"
  }
}
</style>
