<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

		<v-container>
			<v-row dense>
				<v-col>
					<h1 class="nutriyou-h2 text-left">{{ $t("pageTitle") }}</h1>
				</v-col>
				<v-col class="text-right" align-self="center">
					<v-btn icon to="./Bookings">
						<v-icon size="50" color="primary">fas fa-list</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		<div class="d-flex mb-3">
			<v-spacer></v-spacer>

			<v-select v-model="filterByBookingType" multiple  style="max-width: 400px;"
                :items="filterByBookingTypeItems"

                class="mr-3"
				item-text="Name" item-value="Value" @change="RefreshCalendar" label="Servizio" clearable>
			</v-select>

			<v-select v-model="filterByStatus" multiple  style="max-width: 400px;"
                :items="filterByStatusItems" item-text="Name" item-value="Value"
				@change="RefreshCalendar" label="Stato appuntamento" clearable>
			</v-select>
		</div>
    <v-alert type="info">
      Inserisci i tuoi slot disponibili nel calendario. Seleziona un intervallo di tempo!
      </v-alert>
		<div>
      <v-container fluid>
        <v-row dense>
          <v-col>
            <v-btn-toggle
              borderless
              @change="navigate"
              v-model="navigateToSelection"
            >
              <v-btn value="prev"><v-icon>fas fa-chevron-left</v-icon></v-btn>
              <v-btn value="next"><v-icon>fas fa-chevron-right</v-icon></v-btn>
              <v-btn value="today">Oggi</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col><h2 class="text-center poppins-bold">{{calendarTitle}}</h2></v-col>
          <v-col class="text-right">
            <v-btn-toggle
              v-model="selectedView"
              @change="changeView"
              borderless
            >
              <v-btn value="dayGridMonth">Mese</v-btn>
              <v-btn value="timeGridWeek">Settimana</v-btn>
              <v-btn value="timeGridDay">Giorno</v-btn>
            </v-btn-toggle>

          </v-col>
        </v-row>
      </v-container>

<v-progress-linear v-if="LoadingData"></v-progress-linear>
      <FullCalendar :options='calendarOptions' ref="calendar"
                   >
<!--        <template v-slot:eventContent='arg'>-->
<!--          <b>{{ arg.event.title }}</b>-->
<!--        </template>-->
      </FullCalendar>

		</div>

    <AvailableSlotDialog v-model="showAvailableSlotDetails" :available-interval="selectedInterval" @IntervalDeleted="RefreshCalendar"></AvailableSlotDialog>
    <CalendarExport></CalendarExport>

	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import moment from "moment"
import FullCalendar from "@fullcalendar/vue"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"

import CalendarExport from "./CalendarExport.vue"
import AvailableSlotDialog from "./AvailableSlotDialog.vue"

import { getCETDate, getDateOnlyString } from "./dateUtils"

export default {
	components: {
		CalendarExport,
		AvailableSlotDialog,
		FullCalendar
	},

	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			filterByStatus: [1, 2, 4],
			filterByStatusItems: [
				{
					Name: "Prenotato",
					Value: 1
				},
				{
					Name: "Completato",
					Value: 2
				},
				{
					Name: "Annullato",
					Value: 3
				},
				{
					Name: "In attesa di pagamento",
					Value: 4
				}
			],
			filterByBookingType: [],
			filterByBookingTypeItems: [
				{
					Name: "Incontro Conoscitivo",
					Value: 1
				},
				{
					Name: "Visita Nutrizionale",
					Value: 2
				},
				{
					Name: "Consegna e spiegazione del piano",
					Value: 3
				},
			],
			LoadingData: false,

			// for AvailableSlotDialog
			showAvailableSlotDetails: false,
			selectedInterval: null,

			selectedView: "dayGridMonth",
			navigateToSelection: null,
			calendarTitle: null,

			GoogleCalenderPalette: null,
			connectionStatus: null,

			calendarOptions: {
				plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
				initialView: "timeGridWeek",
				weekends: true,
				locale: "it",
				firstDay: 1,
				slotMinTime: "08:00:00",
				slotMaxTime: "22:00:00",
				allDaySlot: false,
				// headerToolbar: {
				// 	left: "prev,next today",
				// 	center: "title",
				// 	right: "dayGridMonth,timeGridWeek,timeGridDay"
				// },
				headerToolbar: false,
				footerToolbar: false,
				height: "auto",
				selectable: false, // Set default state to non-selectable

				// events
				events: this.fetchEvents,
				eventChange: this.OnEventChanged,
				select: this.handleIntervalSelection,
				eventClick: this.onEventClick,
				datesSet: this.handleViewChange, // Callback for when view changes
				// dateClick: this.handleDateClick,
				navLinks: true,
				navLinkDayClick: this.handleNavLinkDayClick
			}
		}
	},
	computed: {

	},

	created() {
		this.BookingsService = new CrudClient("CurrentNutritionist/Bookings");
		this.UnavailableDatesService = new CrudClient("CurrentNutritionist/UnavailableDates");
		this.DailyAvailabilitiesService = new CrudClient("CurrentNutritionist/DailyAvailabilities");
		this.GoogleCalendarConnectService = new CrudClient("Nutritionists/GoogleCalendarConnect");
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.areaNutritionists"),
			disabled: false,
			exact: true,
			to: "/AreaNutritionists",
		});

		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.calendar"),
			disabled: true,
			exact: true,
			to: "/AreaNutritionists/Calendar",
		});

		this.connectionStatus = await this.GoogleCalendarConnectService.Get(null, "ConnectionStatus");
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async fetchEvents(fetchInfo, successCallback, failureCallback) {
			// this.$log.debug("fetchEvents");
			// this.$log.debug(fetchInfo);

			try {
				const events = [];

				this.LoadingData = true;
				const bookings = await this.LoadBookings(fetchInfo.startStr, fetchInfo.endStr);
				const unavailableDates = await this.LoadUnavailableDate(fetchInfo.startStr, fetchInfo.endStr);
				const availableIntervals = await this.LoadAvailableIntervals(fetchInfo.startStr, fetchInfo.endStr);

				if (this.connectionStatus.ConnectionStatus === "connected") {
					const googleCalendarEvents = await this.LoadGoogleCalendarEvents(fetchInfo.startStr, fetchInfo.endStr);

					for (let i = 0; i < googleCalendarEvents.length; i++) {
						events.push({
							availableInterval: googleCalendarEvents[i],
							title: googleCalendarEvents[i].Summary,
							start: googleCalendarEvents[i].StartDateTimeOffset,
							end: googleCalendarEvents[i].EndDateTimeOffset,
							classNames: "nyblue",
							editable: false,
							durationEditable: false,
							extendedProps: null
						})
					}
				}

				for (let i = 0; i < bookings.length; i++) {
					this.$log.debug(bookings[i]);
					const newEvent = {
						title: bookings[i].Patient.DisplayName,
						start: moment(bookings[i].BookingTime).format("YYYY-MM-DD HH:mm"),
						end: moment(bookings[i].BookingTime).add(bookings[i].Duration, "minutes").format("YYYY-MM-DD HH:mm"),
						allDay: false,
						classNames: this.BackgroundColorByBookingStatus(bookings[i].BookingStatusId),
						extendedProps: bookings[i]
					};
					events.push(newEvent);
				}

				for (let i = 0; i < unavailableDates.length; i++) {
					const unavailablePeriodStart = new Date(unavailableDates[i].StartDate);
					const unavailablePeriodEnd = new Date(unavailableDates[i].EndDate);

					const dates = this.getDatesBetween(unavailablePeriodStart, unavailablePeriodEnd);

					dates.forEach(element => {
						events.push({
							title: "Non Disponibile",
							start: element,
							backgroundColor: "grey",
							display: "background",
							allDay: true,
						});
					});
				}

				for (let i = 0; i < availableIntervals.length; i++) {
					events.push({
						availableInterval: availableIntervals[i],
						title: "Slot Disponibile",
						start: availableIntervals[i].IntervalDate + " " + availableIntervals[i].IntervalStart,
						end: availableIntervals[i].IntervalDate + " " + availableIntervals[i].IntervalEnd,
						classNames: "nyGreen",
						editable: true,
						durationEditable: true,
						extendedProps: availableIntervals[i]
					})
				}

				// Send the fetched events to FullCalendar
				successCallback(events);
			} catch (error) {
				this.snackError({ Text: this.$t("cannotLoadData") });
				this.$captureError(error);
				failureCallback(error);
			} finally {
				this.LoadingData = false;
			}
		},

		RefreshCalendar() {
			this.$refs.calendar.getApi().refetchEvents();
		},

		changeView(e) {
			this.$log.debug("changeView");
			this.$log.debug(e);
			this.$refs.calendar.getApi().changeView(this.selectedView); // View type passed as parameter
		},

		handleNavLinkDayClick(date) {
			const calendarApi = this.$refs.calendar.getApi();
			calendarApi.changeView("timeGridDay", date);
		},

		handleViewChange(viewInfo) {
			// Enable selectable only for week and day views
			const calendarApi = this.$refs.calendar.getApi();
			this.calendarTitle = calendarApi?.currentData.viewTitle;
			this.selectedView = viewInfo.view.type;
			if (viewInfo.view.type === "timeGridWeek" || viewInfo.view.type === "timeGridDay") {
				calendarApi.setOption("selectable", true); // Enable selection
			} else {
				calendarApi.setOption("selectable", false); // Disable selection
			}

			this.$log.debug("View changed to:", viewInfo.view.type);
		},

		navigate(action) {
			const calendarApi = this.$refs.calendar.getApi();

			if (action === "next") {
				calendarApi.next(); // Navigate to the next view
			} else if (action === "prev") {
				calendarApi.prev(); // Navigate to the previous view
			} else if (action === "today") {
				calendarApi.today(); // Navigate to today's date
			}

			// trick to reset the btn-group state
			this.$nextTick(() => { this.navigateToSelection = null; });
		},

		BackgroundColorByBookingStatus(bookingStatusId) {
			let color;
			switch (bookingStatusId) {
				case 1:
					color = "blue";
					break;
				case 2:
					color = "success";
					break;
				case 3:
				case 5:
					color = "red";
					break;
				case 4:
					color = "warning";
					break;
			}
			return color;
		},

		async LoadBookings(startDate, endDate) {
			const formattedStartDate = moment(startDate).format("YYYY-MM-DD")
			const formattedEndDate = moment(endDate).add(1, "days").format("YYYY-MM-DD")

			let filter = null;

			filter = `BookingTime:between:${formattedStartDate},${formattedEndDate}`;

			if (this.filterByStatus.length) {
				if (filter) filter += ";";
				else filter = "";
				filter += "BookingStatusId:in:" + this.filterByStatus.join(",");
			}

			if (this.filterByBookingType.length) {
				if (filter) filter += ";";
				else filter = "";

				filter += "BookingTypeId:in:" + this.filterByBookingType.join(",");
			}

			const res = await this.BookingsService.GetPaged({
				skip: 0,
				fields: "*",
				filter: filter,
				orderBy: "BookingId",
			});

			return res.Data;
		},

		async LoadAvailableIntervals(start, end) {
			const formattedStartDate = moment(start).format("YYYY-MM-DD")
			const formattedEndDate = moment(end).add(1, "days").format("YYYY-MM-DD")

			const res = await this.DailyAvailabilitiesService.GetPaged({
				skip: 0,
				fields: "*",
				filter: `IntervalDate:between:${formattedStartDate},${formattedEndDate}`,
			});

			return res.Data;
		},

		async LoadUnavailableDate(start, end) {
			const formattedStartDate = moment(start).format("YYYY-MM-DD")
			const formattedEndDate = moment(end).add(1, "days").format("YYYY-MM-DD")

			const res = await this.UnavailableDatesService.GetPaged({
				skip: 0,
				fields: "*",
				filter: `StartDate:between:${formattedStartDate},${formattedEndDate}`,
			});

			return res.Data;
		},

		async LoadGoogleCalendarEvents(start, end) {
			// {applicationId}/SelectedCalendars/{userSelectedCalendarId}/Events
			const formattedStartDate = moment(start).format("YYYY-MM-DD")
			const formattedEndDate = moment(end).format("YYYY-MM-DD");

			return await this.GoogleCalendarConnectService.Get(null, `SelectedCalendars/Events?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
		},

		// async LoadAvailableSlots(start, end) {
		// 	const formattedStartDate = moment(start).format("YYYY-MM-DD")
		// 	const formattedEndDate = moment(end).format("YYYY-MM-DD");
		//
		// 	return await this.GoogleCalendarConnectService.Get(null, `GetSlots?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
		// },

		async LoadGoogleCalendarColorPalette() {
			this.GoogleCalenderPalette = await this.GoogleCalendarConnectService.Get(null, "CalendarPalette");
		},

		async OnEventChanged({
			event,
			oldEvent,
			revert
		}) {
			this.$log.debug("OnEventChanged");
			this.$log.debug(event.extendedProps.IntervalId);

			const oldEventStartDate = getDateOnlyString(oldEvent.start);

			const startDate = getDateOnlyString(event.start);
			const endDate = getDateOnlyString(event.end);
			if (startDate !== endDate) {
				// Condition: Start days do not match
				// Revert the event to its original position
				revert();
				this.$log.warn("You cannot change the event's start day. It has been reverted.");
			}

			// if info.start date is in a past day unselect
			const todayDate = getDateOnlyString(getCETDate());

			if (todayDate > startDate) {
				revert();
				this.$log.warn("No drag to past day allowed. Event has been reverted.");
				return;
			}

			if (todayDate > oldEventStartDate) {
				revert();
				this.$log.warn("No drag for past event is allowed. Event has been reverted.");
				return;
			}

			if (event.extendedProps.IntervalId) {
				await this.DailyAvailabilitiesService.Patch(event.extendedProps.IntervalId, {
					IntervalDate: startDate,
					IntervalStart: moment(event.start).format("HH:mm"),
					IntervalEnd: moment(event.end).format("HH:mm")
				});
			}
		},

		async handleIntervalSelection(info) {
			// `info` contains the start and end of the selected range
			const selectedInterval = {
				start: info.start, // Start date of the interval
				end: info.end, // End date of the interval
			};

			this.$log.debug("Interval Selected:", selectedInterval);

			const startDate = getDateOnlyString(selectedInterval.start);
			const endDate = getDateOnlyString(selectedInterval.end);

			// if info.start date is in a past day unselect
			const todayDate = getDateOnlyString(getCETDate());

			if (todayDate > startDate) {
				info.view.calendar.unselect();
				this.$log.warn("Interval start date is in a past day, selection cancelled.");
				this.snackError({ Text: this.$t("cannotSelectPastDate") });
				return;
			}

			if (startDate !== endDate) { // Condition: Start days do not match
				info.view.calendar.unselect();
				this.$log.warn("Day mismatch, selection cancelled.");
			}

			await this.DailyAvailabilitiesService.Post(null, {
				IntervalDate: startDate,
				IntervalStart: moment(selectedInterval.start).format("HH:mm"),
				IntervalEnd: moment(selectedInterval.end).format("HH:mm")
			});

			this.RefreshCalendar();
		},

		onEventClick({ event, nativeEvent }) {
			if (event.extendedProps.BookingId) {
				this.$router.push("/AreaNutritionists/Bookings/" + event.extendedProps.BookingId);
			}

			if (event.extendedProps.IntervalId) {
				this.$log.debug(event.extendedProps.IntervalId);
				this.selectedInterval = event.extendedProps;
				this.showAvailableSlotDetails = true;
			}
		},

		getDatesBetween(startDate, endDate) {
			const datesArray = [];
			const currentDate = new Date(startDate);
			// eslint-disable-next-line no-unmodified-loop-condition
			while (currentDate <= endDate) {
				datesArray.push(new Date(currentDate));
				currentDate.setDate(currentDate.getDate() + 1);
			}
			return datesArray;
		}
	}
}

</script>
<i18n>
{
	"it": {
		"pageTitle": "Calendario",
		"pageMetaDescription": "-",
		"NoBookings": "Nessun appuntamento trovato",
    "cannotSelectPastDate": "Impossibile creare un slot in una data passata"
	}
}
</i18n>
<style>
</style>
