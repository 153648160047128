<template>
	<v-card color="nyLightYellow" v-if="PatientProfile && !PatientProfile.IsPasswordSet">
		<v-skeleton-loader type="article, actions" v-if="LoadingPatientProfile"></v-skeleton-loader>
		<div v-else>
      <v-card-title><span class="TitleFont orange--text CardTitleSize">Imposta la tua password</span></v-card-title>
			<v-card-text>
        <div class="poppins-regular ">
          Imposta ora la tua password per accedere facilmente alla tua area personale.
        </div>
				<v-form>
					<v-text-field prepend-icon="lock" :type="showPassword ? 'text' : 'password'"
						v-model="password" label="Password" autocomplete="new-password" v-validate="'required|min:8'"
						data-vv-name="Password" :data-vv-as="$t('common.password')" data-vv-delay="300"
                        hint="La password deve contenere almeno 8 caratteri"
						:error-messages="errors.collect('password')"
            color="orange"
						:append-icon="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
						@click:append="showPassword = !showPassword"></v-text-field>
				</v-form>
        <div class="">
          <v-btn color="orange" class="text-transform-none" dark large block light @click="SetPassword" :loading="UpdatingPassword">
            <v-icon left>fas fa-lock</v-icon>
            Imposta la tua password</v-btn>
        </div>
			</v-card-text>

		</div>
	</v-card>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			password: null,
			UpdatingPassword: false,
			PatientProfile: null,
			LoadingPatientProfile: false,
			showPassword: false,
		};
	},
	created() {
		this.CurrentPatientProfileService = new CrudClient("CurrentPatient/Profile");
	},
	async mounted() {
		await this.LoadCurrentPatientProfile();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		async LoadCurrentPatientProfile() {
			try {
				this.LoadingPatientProfile = true;
				this.PatientProfile = await this.CurrentPatientProfileService.Get();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingPatientProfile = false;
			}
		},
		async SetPassword() {
			const result = await this.$validator.validate();
			if (!result) return;
			try {
				this.UpdatingPassword = true;
				await this.CurrentPatientProfileService.Post(
					null,
					{ Password: this.password },
					"SetPassword"
				);

				this.snackSuccess({ Text: this.$t("resetSuccess") });
				await this.LoadCurrentPatientProfile();

				this.$emit("passwordSet");
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotSetPassword") });
			} finally {
				this.UpdatingPassword = false;
			}
		},
	},
};
</script>
<i18n>
{
	"it":{
		"pageTitle": "Reimposta Password",
		"titleDec": "Puoi reimpostare qui la tua password",
		"yourEmail": "La tua email",
		"resetPassword": "Reimposta Password",
		"password": "Password",
		"password_confirmation": "Conferma password",
		"resetSuccess": "Password reimpostata con successo",
		"resetError": "Impossibile reimpostare la password ",
		"resetErrorUnknown": "Impossibile reimpostare la password (errore sconosciuto)"
	}
}
</i18n>
