<template>
	<div class="about">
		<confirm ref="confirm"></confirm>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
      </template>
		</v-breadcrumbs>

		<div class="elevation-5">
			<v-toolbar flat color="blue-grey lighten-4" class="logViewerHeader">
				<v-toolbar-title>Log Viewer</v-toolbar-title>
				<v-btn icon @click="LoadApplicationLog">
					<v-icon>fas fa-sync-alt</v-icon>
				</v-btn>
			</v-toolbar>

			<skeleton-table
				v-if="LoadingData"
				:columns="7"
				:actions="1"
			></skeleton-table>

			<v-data-table
				v-show="!LoadingData"
				mobile-breakpoint="1"
				:headers="headers"
				:items="items"
				:must-sort="true"
				:footer-props="{
					itemsPerPageOptions: [10, 20, 50, 100],
				}"
				:options.sync="options"
				@update:options="LoadApplicationLog"
				:server-items-length="itemsPaging.TotalItems"
			>
				<template v-slot:body="{ items }">
					<tbody>
						<tr v-for="item in items" :key="item.EventId">
							<td>{{ item.EventId }}</td>
							<td class="text-right">{{ item.Time | formatLocalDateTime }}</td>
							<td class="text-left">
								<v-chip
									color="primary"
									label
									small
									text-color="white"
									v-if="item.HttpRequestMethod"
									>{{ item.HttpRequestMethod }}</v-chip
								>
								{{ item.Url }}
							</td>
							<td class="text-right">{{ item.EventType.Description }}</td>
							<td class="text-left exceptionData">
								<read-more
									more-str="read more"
									less-str="minimize"
									:text="formatException(item.Data)"
									:max-chars="200"
								></read-more>
							</td>
							<td class="text-right">{{ item.IP }}</td>
							<td class="text-right">{{ item.UserName }}</td>
							<td class>
								<v-btn icon ripple @click="DeleteLog(item.EventId)">
									<v-icon color="error">fas fa-fw fa-trash-alt</v-icon>
								</v-btn>
							</td>
						</tr>
					</tbody>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import ConfirmTool from "@/components/Shared/Common/Confirm";
import CrudClient from "@/services/CrudClient/";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageTitle") }],
		};
	},
	name: "LogViewer",
	$_veeValidate: { validator: "LogViewer" },
	components: {
		confirm: ConfirmTool,
	},
	data() {
		return {
			ApplicationLogService: new CrudClient("ApplicationLog"),
			LoadingData: false,
			items: [],
			itemsPaging: {},
			options: {
				page: 1,
				itemsPerPage: 20,
				totalItems: 0,
				sortBy: ["EventId"],
				sortDesc: [true],
			},
			headers: [
				{ text: "ID", align: "left", sortable: true, value: "EventId" },
				{ text: "Time", sortable: true, value: "Time" },
				{ text: "Url", sortable: true, value: "Url" },
				{ text: "Event Type", value: "EventType.Description", sortable: true },
				{ text: "Data", value: "Data", sortable: false },
				{ text: "IP", value: "IP", sortable: false },
				{ text: "UserName", value: "UserName", sortable: true },
				{ text: "Actions", sortable: false },
			],
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
		};
	},
	async mounted() {
		this.breadcrumbsItems.push(
			{
				text: this.$t("systemSettings"),
				disabled: false,
				exact: true,
				to: "/SystemSettings",
			},
			{
				text: this.$t("pageTitle"),
				disabled: true,
				exact: true,
				to: "/SystemSettings/LogViewer",
			}
		);
		this.$log.info("ApplicationLog Viewer Mounted");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadApplicationLog() {
			try {
				this.LoadingData = true;
				const res = await this.ApplicationLogService.GetPaged({
					limit: this.options.itemsPerPage,
					skip: (this.options.page - 1) * this.options.itemsPerPage,
					fields: "*, EventType.Description",
					orderBy:
						this.options.sortBy[0] + (this.options.sortDesc[0] ? ":desc" : ""),
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadLog") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async DeleteLog(EventId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete"),
					{ color: "error" }
				))
			) {
				return;
			}
			this.LoadingData = true;
			try {
				await this.ApplicationLogService.Delete(EventId);
				this.snackSuccess({ Text: this.$t("snackHeader.success") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("snackHeader.error") });
			} finally {
				this.LoadingData = false;
			}
			await this.LoadApplicationLog();
		},

		formatException(exceptionMessage) {
			let result = exceptionMessage || "";
			const searchReplaces = [
				{
					find: /\n/g,
					repl: "<br />",
				}
			];

			searchReplaces.forEach(function (item) {
				result = result.replace(item.find, item.repl);
			});
			return result;
		},
	},
};
</script>

<style lang="scss" scoped>
.exceptionData {
	font-family: "Courier",serif;
}
</style>

<style lang="scss">
.logViewerHeader {
	.v-toolbar {
		&__content {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}
</style>

<i18n>
{
    "it": {
		"pageTitle": "Log Viewer",
		"systemSettings": "System Settings"
    }
}
</i18n>
