<script>
export default {
	name: "PrivacyGoogleCalendar"
}
</script>

<template>
  <div>
    <v-card max-width="1000" class="mx-auto">
      <v-card-text>
  <div class="privacy">
    <h1 class="nutriyou-h2 primary--text text-center">Informativa sulla Privacy per l'Integrazione di Google Calendar</h1>
    <p><strong>Data di entrata in vigore:</strong> 01/01/2025</p>
    <p>
      Presso <strong>Nutriyou SRL</strong>, rispettiamo la tua privacy e ci impegniamo a proteggere le tue informazioni personali.
      Questa Informativa sulla Privacy spiega come utilizziamo, archiviamo e proteggiamo i dati relativi al tuo Google Calendar quando lo colleghi ai nostri servizi.
    </p>

    <h2>1. Chi può utilizzare l'integrazione di Google Calendar</h2>
    <p>
      L'integrazione di Google Calendar è fornita esclusivamente ai nostri nutrizionisti partner.
      Non è offerta ai pazienti o ad altri utenti.
    </p>

    <h2>2. Accesso e utilizzo dei dati</h2>
    <ul>
      <li>Accediamo al tuo Google Calendar solo per fornire le funzionalità concordate, come la sincronizzazione degli appuntamenti o la visualizzazione degli slot disponibili.</li>
      <li>I dettagli dei tuoi eventi su Google Calendar (ad esempio, titolo, descrizione o partecipanti) rimangono privati e non sono visibili al nostro personale, ai pazienti o ad altri utenti.</li>
    </ul>

    <h2>3. Archiviazione dei dati</h2>
    <ul>
      <li>Non archiviamo gli eventi del tuo Google Calendar sui nostri server.</li>
      <li>I dati degli eventi vengono recuperati in tempo reale solo quando necessario per eseguire le funzionalità concordate, come il blocco degli orari non disponibili.</li>
    </ul>

    <h2>4. Visibilità dei tuoi eventi</h2>
    <p>
      I tuoi eventi su Google Calendar sono visibili solo a te.
      Altri utenti, incluso il personale di Nutriyou e i pazienti, non possono visualizzare i dettagli dei tuoi eventi.
    </p>

    <h2>5. Permessi</h2>
    <ul>
      <li>Quando colleghi il tuo Google Calendar, ci concedi autorizzazioni specifiche definite durante il processo di integrazione. Queste autorizzazioni sono utilizzate esclusivamente per:</li>
      <ul>
        <li>Sincronizzare i tuoi appuntamenti.</li>
        <li>Mostrare gli slot disponibili e non disponibili.</li>
        <li>Garantire che non si verifichino doppie prenotazioni.</li>
      </ul>
    </ul>

    <h2>6. Condivisione con terze parti</h2>
    <p>Non condividiamo i dati del tuo Google Calendar con terze parti.</p>

    <h2>7. Sicurezza dei dati</h2>
    <p>
      Adottiamo misure di sicurezza robuste per proteggere i tuoi dati durante la trasmissione.
      Tuttavia, poiché non archiviamo gli eventi del tuo Google Calendar, il rischio di accessi non autorizzati o violazioni dei dati relativi ai tuoi eventi è ridotto al minimo.
    </p>

    <h2>8. Revoca dell'accesso</h2>
    <p>
      Puoi revocare il nostro accesso al tuo Google Calendar in qualsiasi momento tramite le impostazioni del tuo account Google.
    </p>

    <h2>9. Modifiche a questa Informativa</h2>
    <p>
      Possiamo aggiornare periodicamente questa Informativa sulla Privacy. Qualsiasi modifica sarà comunicata tramite aggiornamenti sul nostro sito web o applicazione.
    </p>

    <h2>10. Contattaci</h2>
    <p>
      Se hai domande o dubbi su questa Informativa sulla Privacy o su come gestiamo i tuoi dati,
      ti preghiamo di contattarci all'indirizzo email <a href="mailto:support@nutriyou.it">support@nutriyou.it</a>.
    </p>
  </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped lang="scss">
.privacy{
  h2{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  p{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  ul{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
