<template>
	<v-chip v-bind="$attrs" :color="Status.Color" label small dark> {{ Status.Name }} </v-chip>
</template>

<script>
import { BookingStatusIdEnum } from "@/components/NutriYou/nutriYouBookingStatusEnum";
export default {
	created() {
		this.BookingStatusIdEnum = BookingStatusIdEnum;
	},
	data() {
		return {};
	},
	computed: {
		Status() {
			switch (this.StatusId) {
				case this.BookingStatusIdEnum.BOOKED: //
					return { Name: this.$t("BOOKED"), Color: "nyblue darken-1" };
				case this.BookingStatusIdEnum.COMPLETE:
					return { Name: this.$t("COMPLETE"), Color: "nyGreen darken-2" };
				case this.BookingStatusIdEnum.CANCELLED:
					return { Name: this.$t("CANCELLED"), Color: "nyrose" };
				case this.BookingStatusIdEnum.WAITINGFORPAYMENT:
					return { Name: this.$t("WAITINGFORPAYMENT"), Color: "orange" };
				case this.BookingStatusIdEnum.CANCELLEDWITHFEE:
					return { Name: this.$t("CANCELLEDWITHFEE"), Color: "red" };
			}

			return "-";
		},
	},

	props: {
		StatusId: {
			type: Number,
			required: true,
		},
	},
};
</script>

<style>
</style>
<i18n>
{
	"it":{
		"BOOKED": "Prenotato",
		"COMPLETE": "Completato",
		"CANCELLED": "Annullato",
		"WAITINGFORPAYMENT": "In attesa di pagamento",
		"CANCELLEDWITHFEE": "Annullato (pagato)"
	}
}
</i18n>
