<template>
	<v-card
		class="nyrose text-left chatWidget"

		v-if="
			message.attributes && message.attributes.MessageType === 'BookingCancelled'
		"
		:class="{ 'widget-right': view !== 'received' }"
	>
		<v-card-text class="white--text">
        <v-container fluid>
          <v-row dense >
            <v-col cols="4" md="3" >
              <BookingDateTimeDisplay :date="message.attributes.BookingTime" />
            </v-col>

            <v-col cols="8" md="9" class="d-flex flex-column justify-space-between">
              <!-- Content Title -->
              <div class="mb-2 poppins-semibold CardTitleSize" >
                <span v-if="message.attributes.BookingTypeId === BookingTypesEnum.FREE">{{ $t("freeVisit") }}</span>
                <span v-if="message.attributes.BookingTypeId === BookingTypesEnum.NORMAL">{{ $t("normalVisit") }}</span>
                <span v-if="message.attributes.BookingTypeId === BookingTypesEnum.MEALPLAN">{{ $t("mealPlan") }}</span>
              </div>

              <!-- Buttons are now consistently aligned at the bottom -->
              <div class="text-right mt-auto">
                <v-btn
                  class="nyroseLight nyrose--text"
                  :to="'/Patients/Agenda/' + message.attributes.BookingId"
                  v-if="view === 'received'"
                >
                  {{ $t("common.details") }}
                </v-btn>
                <v-btn
                  text
                  dark
                  :to="'/AreaNutritionists/Bookings/' + message.attributes.BookingId"
                  v-else
                >
                  {{ $t("common.details") }}
                </v-btn>
              </div>
            </v-col>
        </v-row>
      </v-container>

		</v-card-text>
<!--		<v-card-actions>-->
<!--			<v-spacer></v-spacer>-->

<!--		</v-card-actions>-->
	</v-card>
</template>

<script>
import { BookingTypesEnum } from "@/components/NutriYou/nutriYouBookingTypesEnum";
import BookingDateTimeDisplay from "@/views/Patients/components/BookingDateTimeDisplay.vue";

export default {
	components: { BookingDateTimeDisplay },
	created() {
		this.BookingTypesEnum = BookingTypesEnum;
	},
	props: {
		message: {
			type: Object,
			required: true,
		},

		view: {
			type: String,
			required: true,

			validator: (value) => {
				return ["sent", "received"].indexOf(value) !== -1;
			},
		},
	},
};
</script>

<i18n>
{
	"it": {
		"normalVisit": "Visita Nutrizionale Annullata!",
		"freeVisit": "Incontro Conoscitivo Annullato!",
		"mealPlan": "Consegna e spiegazione del piano Annullata!"
	}
}
</i18n>
