// src/utils/dateUtils.js

// Converts a Date object to a YYYY-MM-DD string
export function getDateOnlyString(dateObject) {
	const year = dateObject.getFullYear();
	const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is 0-based, so add 1
	const day = String(dateObject.getDate()).padStart(2, "0");

	return `${year}-${month}-${day}`;
}

// Returns the current date adjusted for Central European Time (CET/CEST)
export function getCETDate() {
	const now = new Date();

	// Get UTC time timestamp (milliseconds since epoch)
	const utcTimestamp = now.getTime() + now.getTimezoneOffset() * 60000;

	// Adjust to Central European Time (CET/CEST)
	const cetOffset = 2 * 60; // CET offset in minutes (UTC+2 or UTC+1 for summer/winter)
	const cetTimestamp = utcTimestamp + cetOffset * 60000;

	// Return the CET/CEST-adjusted date
	return new Date(cetTimestamp);
}
