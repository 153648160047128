<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <div class="limitWidth">

		<v-container>
			<v-row dense>
				<v-col>
					<h1 class="nutriyou-h2 text-left" v-if="!UserId">{{ $t("pageTitle") }}</h1>
					<h1 class="nutriyou-h3 text-left" v-else-if="this.currentPatient">
						Appuntamenti con {{ this.currentPatient.ApplicationUser.DisplayName }}
					</h1>
				</v-col>
				<v-col class="text-right" align-self="center">
					<v-btn icon to="./Calendar">
						<v-icon size="50" color="primary">fas fa-calendar-alt</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<!-- TODO: reset paging -->
		<v-container fluid>
			<v-row>
				<v-col cols="6" md="6" lg="3" v-if="!UserId">
					<PatientPicker v-model="selectedPatientUserId" @change="LoadBookings(true)"></PatientPicker>
				</v-col>
				<v-col cols="6" md="6" lg="3">
					<v-select label="Filtra per data" item-text="Name" item-value="Value" :items="filterByDateOptions"
						v-model="filterByDate" @change="LoadBookings(true)"></v-select>
				</v-col>
				<v-col cols="6" md="6" lg="3">
					<v-select v-model="filterByBookingType" multiple :items="filterByBookingTypeItems" item-text="Name"
						item-value="Value" @change="LoadBookings(true)" label="Servizio" clearable>
					</v-select>

				</v-col>
				<v-col cols="6" md="6" lg="3">
					<v-select v-model="filterByStatus" multiple :items="filterByStatusItems" item-text="Name"
						item-value="Value" @change="LoadBookings(true)" label="Stato appuntamento" clearable>
					</v-select>
				</v-col>
			</v-row>
		</v-container>

		<skeleton-table v-if="LoadingData" :columns="4" :actions="1"></skeleton-table>
		<div v-else>
			<v-alert v-if="!LoadingData && items.length === 0" color="info" dark>
				{{ $t("NoBookings") }}
			</v-alert>
			<BookingCard v-for="booking in items" :key="booking.BookingId" :booking="booking"
				@bookingModified="LoadBookings(false)"></BookingCard>

			<div class="text-center">
				<v-pagination v-if="itemsPaging && items.length > 0" v-model="currentPage"
					:length="itemsPaging.TotalPages" :total-visible="5"></v-pagination>
			</div>
		</div>
	</div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

import BookingCard from "./components/BookingCard.vue";
import PatientPicker from "./components/PatientPicker";
export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	components: {
		BookingCard,
		PatientPicker
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			filterByDate: 1,
			filterByDateOptions: [
				{
					Name: "Futuri",
					Value: 1,
				},
				{
					Name: "Passati",
					Value: 2,
				},
				// {
				// 	Name: "Tutti",
				// 	Value: null,
				// },
			],
			filterByStatus: [],
			filterByStatusItems: [
				{ Name: "Prenotato", Value: 1 },
				{ Name: "Completato", Value: 2 },
				{ Name: "Annullato", Value: 3 },
				{ Name: "In attesa di pagamento", Value: 4 }
			],
			filterByBookingType: [],
			filterByBookingTypeItems: [
				{ Name: "Incontro Conoscitivo", Value: 1 },
				{ Name: "Visita Nutrizionale", Value: 2 },
				{ Name: "Consegna e spiegazione del piano", Value: 3 },
			],
			LoadingData: false,
			items: [],
			itemsPaging: null,
			currentPage: 1,
			pageSize: 20,
			currentPatient: null,
			selectedPatientUserId: null
		};
	},

	computed: {
		UserId() {
			if (!this.$route.params.UserId) return null;
			return this.$route.params.UserId;
		},
	},
	created() {
		this.BookingsService = new CrudClient("CurrentNutritionist/Bookings");
		this.PatientsService = new CrudClient("CurrentNutritionist/Patients");
		this.NutritionistsService = new CrudClient("CurrentNutritionist/Nutritionist");
	},

	watch: {
		async currentPage() {
			await this.LoadBookings();
		},
	},

	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.areaNutritionists"),
			disabled: false,
			exact: true,
			to: "/AreaNutritionists",
		});

		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.bookings"),
			disabled: !this.UserId,
			exact: true,
			to: "/AreaNutritionists/Bookings",
		});

		if (this.UserId) {
			this.currentPatient = await this.PatientsService.Get(this.UserId);

			this.breadcrumbsItems.push({
				text: this.currentPatient.ApplicationUser.DisplayName,
				disabled: true,
				exact: true,
				to: "/AreaNutritionists/Bookings/Patient/" + this.UserId,
			});
		}

		await this.LoadBookings(true);
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		getDateString() {
			const today = new Date();
			// Attention: Janyary is 0
			const formattedToday = `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`;

			return formattedToday;
		},

		async LoadBookings(resetPaging) {
			this.$log.debug("LoadBookings");
			try {
				let filter = null;
				if (this.UserId) {
					filter = "PatientUserId:" + this.UserId;
				}

				let orderBy;
				if (this.filterByDate === 1) {
					orderBy = "BookingTime:asc";
				} else if (this.filterByDate === 2) {
					orderBy = "BookingTime:desc";
				}

				if (this.filterByDate === 1 || this.filterByDate === 2) {
					if (filter) filter += ";";
					else filter = "";

					const formattedToday = this.getDateString();

					filter += `BookingTime:${this.filterByDate === 1 ? "gt" : "lt"}:${formattedToday}`;
				}

				if (this.filterByStatus.length) {
					if (filter) filter += ";";
					else filter = "";
					filter += "BookingStatusId:in:" + this.filterByStatus.join(",");
				}

				if (this.filterByBookingType.length) {
					if (filter) filter += ";";
					else filter = "";

					filter += "BookingTypeId:in:" + this.filterByBookingType.join(",");
				}

				if (resetPaging) {
					this.currentPage = 1;
				}

				this.LoadingData = true;
				const res = await this.BookingsService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "*",
					filter: filter,
					orderBy: orderBy,
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		}
	},
};
</script>
<i18n>
{
	"it": {
		"pageTitle": "I Miei Appuntamenti",
		"pageMetaDescription": "-",
		"NoBookings": "Nessun appuntamento trovato"
	}
}
</i18n>
