import Vue from "vue"
import VueRouter from "vue-router"
import { captureRouterError } from "@/utilities/Sentry"

import Login from "@/views/Base/Login.vue"
import RecoverPassword from "@/views/Base/RecoverPassword.vue"
import ResetPassword from "@/views/Base/ResetPassword.vue"
import Unsubscribe from "@/views/Base/Unsubscribe.vue"

import Error404 from "@/views/Base/Error404.vue"
import SystemSettingRoutes from "@/views/SystemSettings/routes"

import UserManagementRoutes from "@/views/Users/routes"
import AdminRoutes from "@/views/Admin/routes"
import ReportingRoutes from "@/views/Admin/Reporting/routes"
import InvoicingRoutes from "@/views/Admin/Invoicing/routes"
import ContentRoutes from "@/views/Admin/Content/routes"
import AccountRoutes from "@/views/Account/routes"
import PatientsRoutes from "@/views/Patients/routes"
import NutritionistsRoutes from "@/views/Nutritionists/routes"
import PublicRoutes from "@/views/Public/routes"

import Chat from "@/components/Chat/index"

Vue.use(VueRouter)

const routes = [
	...SystemSettingRoutes,
	...UserManagementRoutes,
	...AccountRoutes,
	...PatientsRoutes,
	...AdminRoutes,
	...NutritionistsRoutes,
	...PublicRoutes,
	...ReportingRoutes,
	...InvoicingRoutes,
	...ContentRoutes,
	{
		path: "/Chat",
		name: "Chat",
		component: Chat,
		meta: {
			requiresAuth: true,
		}
	},
	// {
	// 	path: "/about",
	// 	name: "About",
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
	// },
	{
		path: "/login",
		name: "login",
		component: Login
	},
	{
		path: "/RecoverPassword",
		name: "recoverPassword",
		component: RecoverPassword
	},
	{
		path: "/ResetPassword",
		name: "resetPassword",
		component: ResetPassword
	},
	{
		path: "/Unsubscribe/:userId",
		name: "Unsubscribe",
		component: Unsubscribe
	},
	{
		path: "/error/404",
		component: Error404
	},
	{
		path: "*",
		redirect: to => {
			// the function receives the target route as the argument
			captureRouterError(to);
			return "/error/404";
		}
	}
]

const router = new VueRouter({
	mode: "history",
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	},
	base: process.env.BASE_URL,
	routes
})

export default router
