<template>
  <div>
    <v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <ConfirmTool ref="confirm"></ConfirmTool>

    <v-progress-linear indeterminate v-if="!connectionStatus"></v-progress-linear>
    <div v-if="connectionStatus">
      <v-card  v-if="connectionStatus.ConnectionStatus === 'none'" class="grey lighten-3">
        <v-card-text class="poppins-regular">
          <div class="poppins-semibold mb-3" style="font-size: 1.2rem">
          Connetti il tuo account Google Calendar per sincronizzare i tuoi eventi.</div>
          <ul style="font-size: 1.0rem">
            <li>
              Visualizza i tuoi appuntamenti Nutriyou direttamente su Google Calendar.
            </li>
            <li>
              Visualizza gli slot disponibili su Google Calendar.
            </li>
            <li>
              Se hai impegni segnati sul calendario, i pazienti non potranno prenotare gli stessi slot.
            </li>
            <li>
              I tuoi eventi su Google Calendar sono visibili solo a te. Il personale e i pazienti di Nutriyou non possono visualizzare i dettagli degli eventi.
            </li>
            <li>
              I tuoi eventi su Google Calendar non vengono memorizzati sui nostri server.
            </li>
          </ul>
        </v-card-text>
        <v-card-actions class="px-4">
          <v-btn @click="authenticate('google')" x-large block color="white" class="googleButton text-capitalize mb-3"
          :loading="Authenticating">
            <img src="https://nutriyou.blob.core.windows.net/nutriyouweb/BrandIcons/google_calendar160.png"
                 class="mr-2 " style="height: 24px" />
            {{ $t("loginWithGoogle") }}
          </v-btn>
        </v-card-actions>

      </v-card>
      <v-card v-if="connectionStatus.ConnectionStatus === 'connected'">
<v-card-text>
  <v-container fluid>

    <v-row align="center">
      <v-col cols="2" md="">
        <v-img src="https://nutriyou.blob.core.windows.net/nutriyouweb/BrandIcons/google_calendar160.png"
               max-width="80"
               contain></v-img>
      </v-col>
      <v-col class="text-left">
        <span class="poppins-semibold success--text CardTitleSize"> Connesso! <v-icon color="success" small>fas fa-check-circle</v-icon></span>
        <br />
        {{ connectionStatus.GoogleUserName }}</v-col>
      <v-col cols="2">
        <v-btn color="error" :loading="revokingPermission" @click="RevokePermission">Disconnetti</v-btn>
      </v-col>
    </v-row>
  </v-container>

  <div v-if="calendarView">
    <div class="mb-5">
    <h3 class="poppins-semibold">
    Trova i slot disponibili basati su questi calendari:
    </h3>
    <v-checkbox v-for="c in calendarView" :key="c.Id"
                dense
                :label="c.Summary" v-model="c.Selected"
                :readonly="c.Primary === true || c.UserSelectedCalendarId === selectedWriteToCalendar"
                @change="selectCalendar($event, c)">
    </v-checkbox>
    </div>
    <div class="mb-5" v-if="writableCalendars">
        <h3 class="poppins-semibold">
         Crea gli eventi in questo calendario:    </h3>
          <v-select :items="writableCalendars"
                    item-text="Summary" item-value="UserSelectedCalendarId"
                    style="max-width: 600px"
                    v-model="selectedWriteToCalendar" @change="updateWriteCalendar"></v-select>

    </div>
    <div class="mb-5">
      <h3 class="poppins-semibold">
        Impostazioni:    </h3>

      <v-checkbox dense :label="$t('CreateBookingsOnCalendar')" v-model="calendarSettings.CreateBookingsOnCalendar"
                  @change="updateCalendarSettings('CreateBookingsOnCalendar');"></v-checkbox>
      <v-select
        v-model="calendarSettings.BookingsColorId"
        @change="updateCalendarSettings('BookingsColorId');"
        style="max-width: 120px"
        dense
        :label="$t('BookingsColorId')"
        :items="calendarColors"
        item-value="Id"
      >
        <template v-slot:selection="data">
          <div
            v-if="data.item" >

            <div v-if="!data.item.Background">
              default
            </div>
            <div v-else
                 :style="'background-color: ' + data.item.Background"
                 style="width: 30px; height: 30px; border-radius: 50%;"
            >
            </div>

          </div>
        </template>
        <template v-slot:item="{ item }">
          <div v-if="!item.Background">
            default
          </div>
          <div v-else
               :style="'background-color: ' + item.Background"
               style="width: 30px; height: 30px; border-radius: 50%;"
          >
          </div>
        </template>
      </v-select>

      <v-checkbox dense :label="$t('CreateAvailableSlotsOnCalendar')" v-model="calendarSettings.CreateAvailableSlotsOnCalendar"
                  @change="updateCalendarSettings('CreateAvailableSlotsOnCalendar');"
      ></v-checkbox>
      <v-select
        v-model="calendarSettings.AvailableSlotsColorId"
        @change="updateCalendarSettings('AvailableSlotsColorId');"
        style="max-width: 120px"
        dense
        :label="$t('AvailableSlotsColorId')"
        :items="calendarColors"
        item-value="Id"
      >
        <template v-slot:selection="data">
          <div
            v-if="data.item" >

            <div v-if="!data.item.Background">
              default
            </div>
            <div v-else
                 :style="'background-color: ' + data.item.Background"
                 style="width: 30px; height: 30px; border-radius: 50%;"
            >
            </div>

          </div>
        </template>
        <template v-slot:item="{ item }">
          <div v-if="!item.Background">
          default
          </div>
          <div v-else
            :style="'background-color: ' + item.Background"
            style="width: 30px; height: 30px; border-radius: 50%;"
          >
          </div>
        </template>
      </v-select>
    </div>
  </div>

</v-card-text>
      </v-card>

    </div>

  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient";
import ConfirmTool from "@/components/Shared/Common/Confirm.vue";

export default {
	name: "GoogleCalendarConnection",
	components: { ConfirmTool },
	data() {
		return {
			Authenticating: false,
			connectionStatus: null,
			revokingPermission: false,
			calendars: null,
			selectedCalendars: null,
			selectedWriteToCalendar: null,

			calendarColors: [],
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			calendarSettings: {
				AvailableSlotsColorId: null,
				BookingsColorId: null,
				CreateAvailableSlotsOnCalendar: false,
				CreateBookingsOnCalendar: false,
			}
		}
	},
	computed: {
		...mapGetters([
			"UserProfile",
			"selectedApplicationId",
			"getAuthToken"
		]),

		calendarView() {
			if (!this.calendars) { return null; }
			if (!this.selectedCalendars) { return null; }

			return this.calendars.map((calendar) => {
				const selectedCalendar = this.selectedCalendars.find(
					(Selected) => Selected.GoogleCalendarId === calendar.Id
				);

				return {
					Id: calendar.Id,
					Summary: calendar.Summary,
					AccessRole: calendar.AccessRole,
					Primary: calendar.Primary,
					IsWriteToCalendar: selectedCalendar?.IsWriteToCalendar,
					UserSelectedCalendarId: selectedCalendar?.UserSelectedCalendarId,
					Selected: !!selectedCalendar, // Renamed for clarity
				};
			});
		},

		writableCalendars() {
			if (this.calendarView == null) return null;

			return this.calendarView.filter(c => c.AccessRole === "owner" && c.Selected);
		}

	},
	created() {
		this.GoogleCalendarConnectService = new CrudClient("Nutritionists/GoogleCalendarConnect");
		this.GoogleCalendarSettingsService = new CrudClient("Nutritionists/GoogleCalendarSettings");
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.areaNutritionists"),
			disabled: false,
			exact: true,
			to: "/AreaNutritionists",
		});

		this.breadcrumbsItems.push({
			text: this.$t("googleCalendarConnect"),
			disabled: true,
			exact: true,
			to: "/AreaNutritionists/GoogleCalendarConnect",
		});

		this.connectionStatus = await this.GoogleCalendarConnectService.Get(null, "ConnectionStatus");

		if (this.connectionStatus.ConnectionStatus === "connected") {
			await this.LoadCalendars();

			await this.LoadCalendarColors();
			await this.LoadNutritionistCalendarSettings();
		}
	},
	methods: {
		...mapActions([
			"snackSuccess",
			"snackError"]),

		async LoadCalendarColors() {
			const colorsRes = await this.GoogleCalendarConnectService.Get(null, "CalendarPalette");
			this.calendarColors = Object.keys(colorsRes.Event__).map(key => {
				return {
					Id: key, // Use the key as the `Id`
					Background: colorsRes.Event__[key].Background // Keep only the `Background` property
				};
			});

			this.calendarColors.unshift({
				Id: null,
				Background: null
			});
		},

		async LoadNutritionistCalendarSettings() {
			const settings = await this.GoogleCalendarSettingsService.Get();

			this.calendarSettings.AvailableSlotsColorId = settings.AvailableSlotsColorId;
			this.calendarSettings.BookingsColorId = settings.BookingsColorId;
			this.calendarSettings.CreateAvailableSlotsOnCalendar = settings.CreateAvailableSlotsOnCalendar;
			this.calendarSettings.CreateBookingsOnCalendar = settings.CreateBookingsOnCalendar;
		},

		async updateCalendarSettings(updatedField) {
			let patchDto = {};
			switch (updatedField) {
				case "AvailableSlotsColorId":
					patchDto = {
						AvailableSlotsColorId: this.calendarSettings.AvailableSlotsColorId
					}
					break;
				case "BookingsColorId":
					patchDto = {
						BookingsColorId: this.calendarSettings.BookingsColorId
					}
					break;
				case "CreateAvailableSlotsOnCalendar":
					patchDto = {
						CreateAvailableSlotsOnCalendar: this.calendarSettings.CreateAvailableSlotsOnCalendar
					}
					break;
				case "CreateBookingsOnCalendar":
					patchDto = {
						CreateBookingsOnCalendar: this.calendarSettings.CreateBookingsOnCalendar
					}
					break;
			}

			try {
				await this.GoogleCalendarSettingsService.Patch(null, patchDto);
			} catch (e) {
				this.$captureError(e);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			}
		},

		async authenticate(provider) {
			try {
				this.Authenticating = true;
				this.$auth.options.providers.google.url = `Nutritionists/GoogleCalendarConnect/${this.selectedApplicationId}/GrantPermission/${this.UserProfile.Id}`;

				const token = await this.$auth.authenticate(provider);

				this.$log.debug(token);
			} catch (e) {
				this.$log.debug(e);
				// suppress exception thrown when popup window is closed
				if (e.toString() !== "Error: Auth popup window closed") {
					this.$captureError(e);
					this.snackError({ Text: this.$t("snackCannotLogin") });
				}

				// this.snackError({ Text: e.toString() });
			} finally {
				this.Authenticating = false;
			}

			this.connectionStatus = null;
			this.connectionStatus = await this.GoogleCalendarConnectService.Get(null, "ConnectionStatus");

			if (this.connectionStatus.ConnectionStatus === "connected") {
				await this.LoadCalendars();
				await this.LoadCalendarColors();
				await this.LoadNutritionistCalendarSettings();
			}
		},

		async RevokePermission() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("Stai per cancellare la connessione"),
					this.$t("common.confirmDelete"),
					{ color: "error", width: 350 }
				))
			) {
				return;
			}

			try {
				this.revokingPermission = true
				await this.GoogleCalendarConnectService.Post(null, null, "RevokePermission");
			} catch (error) {
				this.snackError({ Text: "Impossibile cancellare l'account" });
				this.$captureError(error);
			} finally {
				this.revokingPermission = false;
			}

			this.connectionStatus = null;
			this.connectionStatus = await this.GoogleCalendarConnectService.Get(null, "ConnectionStatus");
		},

		async LoadCalendars() {
			this.calendars = await this.GoogleCalendarConnectService.Get(null, "Calendars");
			this.selectedCalendars = await this.GoogleCalendarConnectService.Get(null, "SelectedCalendars");

			this.selectedWriteToCalendar = this.writableCalendars.find(c => c.IsWriteToCalendar === true)?.UserSelectedCalendarId;
		},

		async selectCalendar(event, calendar) {
			if (event === true) {
				const dto = {
					GoogleCalendarId: calendar.Id,
					Summary: calendar.Summary
				}
				await this.GoogleCalendarConnectService.Post(null, dto, "SelectedCalendars");
			} else {
				await this.GoogleCalendarConnectService.Delete(null, "SelectedCalendars/" + calendar.UserSelectedCalendarId);
			}

			this.selectedCalendars = await this.GoogleCalendarConnectService.Get(null, "SelectedCalendars");
		},

		async updateWriteCalendar(event) {
			this.$log.debug(event);

			await this.GoogleCalendarConnectService.Post(null, null, "SelectedCalendars/" + event + "/WriteToCalendar");

			this.selectedCalendars = await this.GoogleCalendarConnectService.Get(null, "SelectedCalendars");
		}

	}
}
</script>

<style scoped lang="scss">

</style>
<i18n>
{
  "it": {
    "loginWithGoogle": "Sincronizza Eventi con Google Calendar",
    "googleCalendarConnect": "Google Calendar Connect",
    "CreateAvailableSlotsOnCalendar"	: "Crea gli slot disponibili su Google Calendar",
    "AvailableSlotsColorId"	: "Colore slot disponibili",
    "CreateBookingsOnCalendar"	: "Crea gli eventi su Google Calendar",
    "BookingsColorId"	: "Colore eventi"
  }
}
</i18n>
