<template>
  <div class="BorderedBox rounded pa-5 mb-3">
    <h4 class="TitleFont primary--text mb-2 CardTitleSize"
        >Serve Assistenza?</h4>
    <div class="mb-2 poppins-regular">
      <router-link to="/FAQ">
        <v-icon class="mr-2" color="info">fas fa-fw fa-question-circle</v-icon>
        Consulta le FAQ
      </router-link>
    </div>
    <div class="mb-2 poppins-regular">
      <a href="https://wa.me/3342993235" target="_blank">
        <v-icon color="info" class="mr-2">fab fa-fw fa-whatsapp</v-icon>
        Scrivici su Whatsapp
      </a>
    </div>
    <div class="mb-2 poppins-regular">
      <a href="https://wa.me/3342993235" target="_blank" >
        <v-icon class="mr-2" color="info">fas fa-fw fa-lightbulb</v-icon>
        Vuoi cambiare il tuo nutrizionista? Nessun problema, puoi sempre  richiedere il cambio!
      </a>
    </div>
  </div>
</template>

<script>
export default {
	name: "AssistanceBox"
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.BorderedBox{
  background-color: $ny_blueLight;
  border: 2px solid $ny_blue!important;
}
</style>
