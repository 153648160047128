<template>
	<v-card
    class="nyGreenLight text-left chatWidget"
    v-if="
		message.attributes && message.attributes.MessageType === 'BookingCreated'
	" :class="{ 'widget-right': view !== 'received' }">
		<v-card-text >
      <v-container fluid>
        <v-row dense >
          <v-col cols="4" md="3" >
            <BookingDateTimeDisplay :date="message.attributes.BookingTime" />
          </v-col>
          <v-col cols="8" md="9" class="d-flex flex-column justify-space-between">
            <div class="mb-2 poppins-semibold CardTitleSize" >
              <span v-if="message.attributes.BookingTypeId === BookingTypesEnum.FREE">{{ $t("freeVisit") }}</span>
              <span v-if="message.attributes.BookingTypeId === BookingTypesEnum.NORMAL">{{ $t("normalVisit") }}</span>
              <span v-if="message.attributes.BookingTypeId === BookingTypesEnum.MEALPLAN">{{ $t("mealPlan") }}</span>
            </div>
            <div class="poppins-regular InfoText"
                 v-if="view === 'received' && message.attributes.BookingTypeId !== BookingTypesEnum.FREE">
              ti ricordiamo di effettuare il pagamento entro 2 giorni dall’appuntamento, in caso contrario la prenotazione verrà annullata
            </div>
            <div class="text-right mt-auto">
              <v-btn text :to="'/Patients/Agenda/' + message.attributes.BookingId" v-if="view === 'received'">{{
                  $t("common.details") }}</v-btn>
              <v-btn text :to="'/AreaNutritionists/Bookings/' + message.attributes.BookingId" v-else>{{ $t("common.details")
                }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>

		</v-card-text>

	</v-card>
</template>
<script>
import { BookingTypesEnum } from "@/components/NutriYou/nutriYouBookingTypesEnum";
import BookingDateTimeDisplay from "@/views/Patients/components/BookingDateTimeDisplay.vue";

export default {
	components: { BookingDateTimeDisplay },
	created() {
		this.BookingTypesEnum = BookingTypesEnum;
	},
	props: {
		message: {
			type: Object,
			required: true,
		},

		view: {
			type: String,
			required: true,

			validator: (value) => {
				return ["sent", "received"].indexOf(value) !== -1;
			},
		},
	},
// 	1	Incontro Conoscitivo
// 2	Visita Nutrizionale
};
</script>

<i18n>
{
	"it": {
		"normalVisit": "Visita Nutrizionale Creata!",
		"freeVisit": "Incontro Conoscitivo Creato!",
		"mealPlan": "Consegna e spiegazione del piano Creata!"
	}
}
</i18n>
