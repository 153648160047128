<template>
	<div class="limitWidth">

		<div v-if="!initialSetupComplete">
			<v-container>
				<v-row>
					<v-col cols="12" md="6">
						<v-skeleton-loader class="mx-auto border" max-width="300"
							type="image, article"></v-skeleton-loader>
					</v-col>

					<v-col cols="12" md="6">
						<v-skeleton-loader class="mx-auto border" max-width="300"
							type="image, article"></v-skeleton-loader>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div v-else>

      <v-container fluid>
					<v-row dense>
            <v-col cols="12" order="1" order-md="1">
                <div class="my-2">
                  <div class="poppins-semibold nyGreenAlt--text text--darken-2 mb-2" style="font-size: 0.8rem">
                    Ciao {{ UserProfile.Name }}!
                  </div>
                  <div
                       class="poppins-regular"
                       :class="{
              'WelcomeTextMessage': $vuetify.breakpoint.smAndDown,
              'WelcomeTextMessageMdAndUp':  $vuetify.breakpoint.mdAndUp}"
                  >

                    <template v-if="OnboardingStatus === 1">Abbiamo trovato {{ NutritionistProfile.Gender === 1 ? 'il tuo ' : 'la tua '}} nutrizionista!</template>
                    <template v-if="OnboardingStatus === 2">Il tuo percorso sta per iniziare!</template>
                    <template v-if="OnboardingStatus === 3">Il cambiamento inizia oggi!</template>
                    <template v-if="OnboardingStatus === 4">
                      <span v-if="!hasExpiringBooking">Un piccolo promemoria: la visita aspetta il suo pagamento!</span>
                      <span v-else> <v-icon size="18">fas fa-alarm-exclamation</v-icon> Ultima chiamata: manca poco per pagare e confermare!</span>
                    </template>
                    <template v-if="OnboardingStatus === 5">Manca poco alla tua visita nutrizionale</template>
                    <template v-if="OnboardingStatus === 6">Il tuo percorso è appena iniziato!</template>
                    <template v-if="OnboardingStatus === 7">
                      <span v-if="!hasExpiringBooking">
                        Un piccolo promemoria: la visita aspetta il suo pagamento!
                      </span>
                      <span v-else> <v-icon size="18">fas fa-alarm-exclamation</v-icon> È quasi ora! Ricorda che la scadenza per il pagamento si avvicina!</span>

                    </template>
                    <template v-if="OnboardingStatus === 8">Manca poco alla tua visita nutrizionale</template>
                    <template v-if="OnboardingStatus === 9">Non fermarti: il tuo viaggio è in piena crescita! 🌱
                    </template>

                  </div>
                  <div class="subtitle-2 font-italic mt-2 grey--text text--darken-1" v-if="0">
                    <v-icon x-small class="mb-2 grey--text">fas fa-quote-left</v-icon>
                    {{ randomPhrase}}
                    <v-icon x-small class="mb-2 grey--text">fas fa-quote-right</v-icon>
                  </div>
                </div>

            </v-col>

						<v-col cols="12" md="6" order="1" order-md="2">
              <template v-if="OnboardingStatus === 1">
                <v-card color="nyLightYellow" class="mb-3" v-if="!IsCalendarSlotsAvailable  || NoAvailableSlotsFound" to="/Chat">
                  <v-card-text>
                    <div class="orange--text text--darken-2 TitleFont text-center mb-3 CardTitleSize">
                      Prenota l'incontro conoscitivo</div>
                    <div class="poppins-regular text-center">
                      Scrivi ora {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutritionista per
                      prenotare    <span class="font-weight-bold">l'incontro
                      gratuito</span>.

                    </div>
                    <v-divider class="my-2"></v-divider>
                    <div class="mb-2">
                      <v-icon small color="orange darken-2" class="mr-2">fas fa-fw fa-seedling </v-icon>
                      Una videochiamata di circa 20 minuti  per condividere le tue esigenze e scoprire il percorso personalizzato proposto dal tuo nutrizionista</div>

                    <div class="poppins-regular">
                      <v-icon small color="orange darken-2" class="mr-2">fas fa-fw fa-info-circle</v-icon> Se avrai un imprevisto potrai sempre spostare o annullare la videochiamata.

                    </div>

                  </v-card-text>
                </v-card>
                <v-card  color="nyLightYellow" class="mb-3"  v-if="IsCalendarSlotsAvailable && !NoAvailableSlotsFound">
                  <v-card-text class="pb-0">
                    <div class="orange--text text--darken-2 TitleFont text-center mb-2 CardTitleSize">
                      Prenota l'incontro conoscitivo</div>
                    <div class="poppins-regular" style="font-size: 0.9rem;">

                      <div class="mb-2">
                        <v-icon small color="orange darken-2" class="mr-2">fas fa-fw fa-seedling </v-icon>
                        Una videochiamata <span class="poppins-bold">gratuita</span> di circa 20 minuti  per condividere le tue esigenze e scoprire il percorso personalizzato proposto dal tuo nutrizionista</div>
                      <div class=" mb-2">
                      <v-icon small color="orange darken-2" class="mr-2">fas fa-fw fa-calendar-alt </v-icon>
                      Puoi fissarlo direttamente tu scegliendo il momento che preferisci tra le proposte qui sotto.
                      </div>
                      <div class="mb-2">

                      <v-icon small color="orange darken-2" class="mr-2">fas fa-fw fa-comment-alt-dots </v-icon>
                      Se non ti è possibile in nessuna delle proposte
                      <router-link to="/Chat" class="poppins-bold grey--text text--darken-2"> scrivi {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}}
                        nutritionista in chat
                      </router-link>!
                      </div>
                    </div>
                  </v-card-text>

                  <template v-if="$vuetify.breakpoint.mdAndUp">
                    <v-divider class="mx-4 mt-5 mb-3"></v-divider>
                    <div class="orange--text TitleFont text-center mb-2 CardTitleSize"
                      >
                      Scegli la data e l'ora</div>
                  </template>
                  <CreateEventFromCalendar v-if="IsCalendarSlotsAvailable"
                                           @bookingCreated="OnBookingCreatedFromCalendar"
                                           @noAvailableSlots="NoAvailableSlotsFound = true">
                  </CreateEventFromCalendar>
                </v-card>
              </template>

              <v-card color="nyroseLight" class="mb-3" v-if="OnboardingStatus === 2">
                <v-card-text>
                  <div class="nyrose--text TitleFont text-center mb-3 CardTitleSize" >
                    Hai l'incontro conoscitivo gratuito fissato</div>
                  <div class="poppins-regular text-center">
                    <div v-for="booking in bookings" :key="booking.BookingId">
                      <MiniBookingCard :booking="booking">
                      </MiniBookingCard>
                    </div>
                  </div>
                  <div class="poppins-regular"
                  :class="{'px-5': $vuetify.breakpoint.mdAndUp}">

                   <v-icon small color="nyrose" class="mr-2">fas fa-fw fa-info-circle</v-icon> Se avrai un imprevisto potrai sempre spostare o annullare la videochiamata.
                    <br />
                    <v-icon small color="nyrose" class="mr-2">fas fa-fw fa-comment-alt-dots </v-icon>Ti basterà inviare un messaggio {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutrizionista in chat
                  </div>
                </v-card-text>
              </v-card>

              <v-card color="nyroseLight" class="mb-3" v-if="OnboardingStatus === 3">
                <v-card-text>
                  <div class="nyrose--text TitleFont text-center mb-3 CardTitleSize">
                   Prenota la tua prima visita</div>
                  <div class="poppins-regular text-center">
                    Scrivi {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutrizionista in chat per prenotare
                    la tua prossima visita nutrizionale
                    e ricevere un supporto su misura per raggiungere i tuoi obiettivi.
                    <br />

                    <div class="text-center mt-3">
                    <v-btn class="text-transform-none" dark large color="nyrose" to="/Chat">
                      <v-icon left>fas fa-fw fa-comment-alt-dots</v-icon>
                      Scrivi {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutrizionista
                    </v-btn>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card color="nyroseLight" class="mb-3" v-if="OnboardingStatus === 4 || OnboardingStatus === 7">
                <v-card-text>
                  <div class="nyrose--text TitleFont text-center mb-3 CardTitleSize">
                    Il prossimo appuntamento</div>

                  <div class="poppins-regular text-center">
                    <div v-for="booking in bookings.filter(b => b.BookingTypeId != BookingTypesEnum.FREE && b.BookingStatusId == BookingStatusIdEnum.WAITINGFORPAYMENT)" :key="booking.BookingId">
                      <MiniBookingCard :booking="booking">
                      </MiniBookingCard>
                    </div>
                  </div>

                  <div class="poppins-regular">
                    <v-icon small color="nyrose" class="mr-2">fas fa-fw fa-info-circle</v-icon> Se avrai un imprevisto potrai spostare o annullare la videochiamata entro 2 giorni dall'appuntamento.
                    <br />
                    <v-icon small color="nyrose" class="mr-2">fas fa-fw fa-comment-alt-dots </v-icon> Ti basterà inviare un messaggio {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutrizionista in chat.
                  </div>
                </v-card-text>
              </v-card>

              <v-card color="nyroseLight" class="mb-3" v-if="OnboardingStatus === 5 || OnboardingStatus === 8">
                <v-card-text>
                  <div class="nyrose--text TitleFont text-center mb-3 CardTitleSize">
                    Il prossimo appuntamento</div>
                  <div></div>
                  <div class="poppins-regular text-center">
                    <div v-for="booking in bookings.filter(b => b.BookingTypeId != BookingTypesEnum.FREE && b.BookingStatusId == BookingStatusIdEnum.BOOKED)" :key="booking.BookingId">
                      <MiniBookingCard :booking="booking">
                      </MiniBookingCard>
                    </div>
                  </div>
                  <div class="poppins-regular">
                    <v-icon small color="nyrose" class="mr-2">fas fa-fw fa-info-circle</v-icon> Se avrai un imprevisto potrai spostare o annullare la videochiamata entro 2 giorni dall'appuntamento.
                    <br />
                    <v-icon small color="nyrose" class="mr-2">fas fa-fw fa-comment-alt-dots </v-icon> Ti basterà inviare un messaggio  {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutrizionista in chat
                  </div>
                </v-card-text>
              </v-card>

              <v-card color="nyroseLight" class="mb-3" v-if="OnboardingStatus === 6 || OnboardingStatus === 9">
                <v-card-text>
                  <div class="nyrose--text TitleFont text-center mb-3" style="font-size: 1rem;">
                    Prenota il tuo prossimo appuntamento</div>
                  <div></div>
                  <div class="poppins-regular mb-2">
                    Appuntamenti regolari possono essere di grande aiuto per raggiungere i tuoi obiettivi e mantenere il tuo equilibrio.

                  </div>

                  <div class="poppins-regular text-center">
                    <v-btn color="nyrose"
                           class="text-transform-none"
                           dark block large
                           to="/Chat">
                      <v-badge color="red" v-if="TotalNewMessages > 0" overlap>
                        <template v-slot:badge>
                          <span>{{ TotalNewMessages }}</span>
                        </template>
                        <v-icon color="white" left>fas fa-fw fa-comment-alt-dots</v-icon>
                      </v-badge>
                      <v-icon color="white" left v-else>fas fa-fw fa-comment-alt-dots</v-icon>
                      <span :class="{ 'ml-3': TotalNewMessages}">
              {{ TotalNewMessages > 0 ?
                        (NutritionistProfile.Gender === 1 ? "Rispondi al tuo nutrizionista" : "Rispondi alla tua nutrizionista" ):
                        (NutritionistProfile.Gender === 1 ? "Scrivi al tuo nutrizionista" : "Scrivi alla tua nutrizionista")
                        }}</span>
                    </v-btn>

                  </div>

                </v-card-text>
              </v-card>

              <template v-if="$vuetify.breakpoint.mdAndUp">
                  <AssistanceBox></AssistanceBox>
                  <SetPasswordCard class="mx-auto"></SetPasswordCard>
              </template>
            </v-col>
            <v-col cols="12" md="6" order="2" order-md="1"
                   v-if="initialSetupComplete">
							<NutritionistProfileExpandableCard
                 :matching-skills="matchingSkills"
                 :nutritionist-profile="NutritionistProfile"
                 :nutritionist-tags="nutritionistTags">
                <template v-slot:mail-button v-if="OnboardingStatus === 1">
                  <v-btn color="nyGreenAlt darken-2"
                         class="text-transform-none"
                         dark block large
                         to="/Chat">
                    <v-badge color="red" v-if="TotalNewMessages > 0" overlap>
                      <template v-slot:badge>
                        <span>{{ TotalNewMessages }}</span>
                      </template>
                      <v-icon color="white" left>fas fa-fw fa-comment-alt-dots</v-icon>
                    </v-badge>
                    <v-icon color="white" left v-else>fas fa-fw fa-comment-alt-dots</v-icon>
                    <span :class="{ 'ml-3': TotalNewMessages}">Prenota l'incontro gratuito</span>
                  </v-btn>
                </template>
              </NutritionistProfileExpandableCard>

						</v-col>
            <template v-if="$vuetify.breakpoint.smAndDown">
              <v-col cols="12" md="6" order="3">
                <AssistanceBox></AssistanceBox>
              </v-col>
              <v-col cols="12" md="6" order="4">
                <SetPasswordCard class="mx-auto"></SetPasswordCard>
              </v-col>
            </template>
					</v-row>
				</v-container>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SetPasswordCard from "./SetPassword";
import CrudClient from "@/services/CrudClient/";
import NutritionistProfileExpandableCard from "@/views/Patients/components/NutritionistProfileExpandableCard.vue";
import AssistanceBox from "@/views/Patients/components/AssistanceBox.vue";
import MiniBookingCard from "@/views/Patients/components/MiniBookingCard.vue";
import { BookingTypesEnum } from "@/components/NutriYou/nutriYouBookingTypesEnum";
import { BookingStatusIdEnum } from "@/components/NutriYou/nutriYouBookingStatusEnum";
import moment from "moment/moment";
import CreateEventFromCalendar from "@/views/Patients/components/CreateEventFromCalendar.vue";

export default {
	components: {
		CreateEventFromCalendar,
		MiniBookingCard,
		AssistanceBox,
		NutritionistProfileExpandableCard,
		SetPasswordCard
	},
	data() {
		return {
			LoadingData: false,
			bookings: [],

			PatientProfile: null,
			NutritionistProfile: null,
			initialSetupComplete: false,
			matchingSkills: [],
			nutritionistTags: [],
			hasExpiringBooking: false,

			IsCalendarSlotsAvailable: false,
			NoAvailableSlotsFound: false,

			phrases: [
				"Ritrova l'armonia con il tuo corpo, un pasto alla volta.",
				"Mangia con il cuore, non con le regole.",
				"Il tuo corpo sa di cosa ha bisogno: impariamo ad ascoltarlo insieme.",
				"Libera la tua alimentazione da sensi di colpa e restrizioni.",
				"Non è una dieta, è un viaggio verso il benessere.",
				"Riconnettiti con i segnali naturali di fame e sazietà.",
				"Ogni corpo è unico: abbraccia il tuo con gentilezza e cura.",
				"Dimentica le diete, scopri la libertà di scegliere.",
				"Nutrire il corpo è un atto d’amore: inizia oggi.",
				"Benessere senza regole rigide: solo ascolto e consapevolezza.",
				"Io sono qui per supportarti",
				"La salute inizia da dentro, non da una tabella calorica.",
				"Imparare ad ascoltarsi è il primo passo verso una vita sana.",
				"Più consapevolezza, meno stress: il cibo come alleato."
			],
			randomPhrase: ""
		}
	},
	props: {
		OnboardingStatus: {
			type: Number,
			required: true
		}
	},
	computed: {
		BookingStatusIdEnum() {
			return BookingStatusIdEnum
		},
		BookingTypesEnum() {
			return BookingTypesEnum
		},
		...mapGetters(["UserProfile", "TotalNewMessages"]),
	},
	created() {
		this.CurrentPatientProfileService = new CrudClient("CurrentPatient/Profile");
		this.BookingsService = new CrudClient("CurrentPatient/Bookings");
		this.CurrentPatientNutritionistService = new CrudClient("CurrentPatient/Nutritionist");
		this.PublicNutritionistsService = new CrudClient("Nutritionists/Public");
		this.UserTags = new CrudClient("SGP/UserTags");
	},
	watch: {
		OnboardingStatus: async function(newVal, oldVal) {
			await this.LoadBookings();
		}
	},
	async mounted() {
		this.pickRandomPhrase();
		await this.InitialSetup();
	},

	beforeDestroy() {
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		pickRandomPhrase() {
			// Randomly pick a phrase from the phrases array
			const randomIndex = Math.floor(Math.random() * this.phrases.length);
			this.randomPhrase = this.phrases[randomIndex];
		},

		async InitialSetup() {
			this.PatientProfile = await this.CurrentPatientProfileService.Get();
			if (this.PatientProfile.DefaultNutritionistUserId) {
				await this.LoadBookings();

				this.NutritionistProfile = await this.PublicNutritionistsService.Get(this.PatientProfile.DefaultNutritionistUserId);
				try {
					this.matchingSkills = await this.CurrentPatientNutritionistService.Get("MatchingSkills");
					const res = await this.CurrentPatientNutritionistService.Get("IsCalendarSlotsAvailable");
					this.IsCalendarSlotsAvailable = res.CalendarSlotsAvailable;
				} catch (error) {
					this.matchingSkills = [];
					this.$captureError(error);
				}

				try {
					this.nutritionistTags = (
						await this.UserTags.Get("User/" + this.PatientProfile.DefaultNutritionistUserId)
					).Data;
				} catch (error) {
					this.$captureError(error);
				}
			}
			this.initialSetupComplete = true;
		},

		getDateString() {
			const today = new Date();
			// Attention: Janyary is 0
			return `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`;
		},
		async LoadBookings() {
			try {
				const orderBy = "BookingTime:asc";

				const formattedToday = this.getDateString();

				let filter = `BookingTime:gt:${formattedToday}`;

				filter += ";BookingStatusId:in:1,2,4"

				const pageSize = 3;

				this.LoadingData = true;
				const res = await this.BookingsService.GetPaged({
					limit: pageSize,
					skip: 0,
					fields: "*, BookingType.Name",
					filter: filter,
					orderBy: orderBy,
				});
				this.bookings = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}

			// check if there is a booking with waiting payment state and near to expire date
			const now = moment();

			this.hasExpiringBooking = this.bookings
				.filter(b => b.BookingTypeId !== BookingTypesEnum.FREE && b.BookingStatusId === BookingStatusIdEnum.WAITINGFORPAYMENT)
				.some(b => (moment(b.BookingTime).diff(now, "days") <= 3));
		},

		OnBookingCreatedFromCalendar() {
			this.$emit("OnBookingCreatedFromCalendar");
		}
	}
}
</script>
<i18n>
	{
		"it":{
		}
	}
</i18n>
<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.BorderedBox{
  background-color: $ny_blueLight;
  border: 2px solid $ny_blue!important;
}

.WelcomeTextMessage {
  font-size: 1.2rem;
}

.WelcomeTextMessageMdAndUp {
  font-size: 1.6rem;
}
</style>
