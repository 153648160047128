import Vue from "vue";
import VueAxios from "vue-axios";
import VueAuthenticate from "vue-authenticate";
import axios from "axios";

Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
	baseUrl: process.env.VUE_APP_ROOT_API,
	providers: {
		google: {
			clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
			name: "google",
			url: "Nutritionists/GoogleCalendarConnect/C091A4A5-E083-4D76-A1A1-9ABA0210A031/GrantPermission",
			authorizationEndpoint: "https://accounts.google.com/o/oauth2/auth",
			redirectUri: process.env.VUE_APP_GOOGLE_REDIRECT_URI,
			requiredUrlParams: ["scope", "access_type", "prompt"],
			optionalUrlParams: ["display"],
			scope: ["profile", "email", "https://www.googleapis.com/auth/calendar"],
			prompt: "consent",
			accessType: "offline",
			scopePrefix: "openid",
			scopeDelimiter: " ",
			display: "popup",
			oauthType: "2.0",
			popupOptions: {
				width: 452,
				height: 633
			}
		}
	}
});

export function getRedirectUri(uri) {
	try {
		return (uri !== undefined)
			? `${window.location.origin}${uri}`
			: window.location.origin
	} catch (e) {
		return null;
	}
}
