<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
      </template>
		</v-breadcrumbs>

		<v-card class="elevate-5">
			<v-toolbar flat class="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("pageTitle") }}</v-toolbar-title>
			</v-toolbar>

			<v-row class="mx-1 py-3">
				<v-col
					cols="12"
					sm="6"
					md="4"
					class="py-1 static-navigation"
					v-for="item in staticNavigation"
					:key="item.text"
				>
					<v-card
						dark
						color="secondary"
						class="pa-1 elevateOnHover"
						:to="{ path: item.path }"
						append
					>
						<v-card-text>
							<v-icon class="mr-2" v-if="item.icon">{{ item.icon }}</v-icon>
							<span class="ItemText white--text">{{ item.text }}</span>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-divider></v-divider>

			<v-row class="mx-1 py-3">
				<v-col
					cols="12"
					sm="6"
					md="4"
					class="py-1 static-navigation"
					v-for="item in items"
					:key="item.text"
				>
					<v-card
						dark
						color="purple lighten-2"
						class="pa-1 elevateOnHover"
						:to="{
							path: 'Content/WebNews',
							query: { WebNewsTypeId: item.NewsTypeId },
						}"
					>
						<v-card-text>
							<v-icon class="mr-2">{{ item.AdminIconType }}</v-icon>
							<span class="ItemText white--text">{{ item.TypeName }}</span>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
import CrudClient from "@/services/CrudClient/";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageTitle") }],
		};
	},
	data() {
		return {
			WebNewsTypesService: new CrudClient("WebNewsTypes"),
			items: [],
			LoadingData: false,
			staticNavigation: [],
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
			],
		};
	},
	async mounted() {
		this.staticNavigation.push(
			{
				path: "StaticContent",
				text: this.$t("staticContent"),
				icon: "fas fa-fw fa-font",
			},
			{
				path: "Galleries",
				text: this.$t("imageGalleries"),
				icon: "fas fa-fw fa-mountains",
			},
			// {
			// 	path: "WebArticleCategories",
			// 	text: this.$t("articleCategories"),
			// 	icon: "fas fa-fw fa-file-image"
			// },
			// {
			// 	path: "WebArticles",
			// 	text: this.$t("articles"),
			// 	icon: "fas fa-fw fa-pen-nib"
			// },
			{
				path: "WebNewsTypes",
				text: this.$t("webNewsTypes"),
				icon: "fas fa-fw fa-comment-alt-lines",
			}
		);
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/Admin/Content",
		});
		await this.LoadWebNewsTypesList();
	},
	methods: {
		async LoadWebNewsTypesList() {
			this.LoadingData = true;
			try {
				const res = await this.WebNewsTypesService.GetPaged({
					limit: 0,
					skip: 0,
					fields: "NewsTypeId, TypeName, AdminIconType",
					orderBy: "NewsTypeId",
				});
				this.items = res.Data;
			} catch (error) {
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>

<style lang="scss">
a {
	text-decoration: none;
}
.ItemText {
	font-size: 120%;
}
</style>

<i18n>
{
	"it": {
		"pageTitle": "Content Home Page",
		"staticContent": "Contenuti statici",
		"imageGalleries": "Gallery immagini",
		"authors": "Autori",
		"articleCategories": "Categorie degli articoli",
		"articles": "Articoli",
		"webNewsTypes": "Tipologie di WebNews",
		"webNews": "WebNews",
		"menus": "Menu"
	}
}
</i18n>
