<template>
	<div>
		<div v-if="isUserInRole('Patient')">
			<PatientHome></PatientHome>
		</div>
		<div v-else class="home">
			<h1 class="nutriyou-h1 primary--text">
				Il nutrizionista online su misura per te
			</h1>
			<div class="ny-roseLightBg rounded py-4 px-8 limitWidth">
				<h3 class="nutriyou-h3 ny-roseText mb-6">
					Compila il questionario, al resto ci pensa Nutriyou
				</h3>
				<v-container fluid>
					<v-row dense>
						<v-col cols="12" md="6">
							<div class="home-card-text">
								In base alle tue risposte il nostro algoritmo ti assegnerà il
								<span class="font-weight-bold">nutrizionista più adatto</span> per aiutarti a
								raggiungere i tuoi
								obiettivi con
								un percorso 100% online. Potrai conoscerlo con un primo <span
									class="font-weight-bold">incontro
									gratuito</span>
								e decidere se è la persona giusta per te
							</div>
							<div class="pa-2 mt-5 text-center" v-if="$vuetify.breakpoint.mdAndUp">
								<v-btn color="nyrose white--text" x-large
									to="/Trova-il-tuo-Nutrizionista">Vai al
									questionario</v-btn>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<div :class="{ 'ma-3': $vuetify.breakpoint.smAndDown }">
								<v-img max-height="220" contain style="display: inline-block;"
									src="https://nutriyou.blob.core.windows.net/nutriyouweb/Illustrations/illustrazioneHome.png"></v-img>
							</div>
						</v-col>
					</v-row>
				</v-container>
				<div class="pa-2" v-if="$vuetify.breakpoint.smAndDown">
					<v-btn color="nyrose white--text" large :block="$vuetify.breakpoint.smAndDown"
						to="/Trova-il-tuo-Nutrizionista">Vai al
						questionario</v-btn>
				</div>
			</div>

			<div class="limitWidth mb-10">
				<div class="px-5 mb-4" v-for="block in contentBlock" :key="block.Title">
					<div class="text-center pt-5">
						<v-img width="110" style="display:inline-block" :src="block.src"></v-img>
					</div>
					<h3 class="text-center nutriyou-h3 primary--text">{{ block.Title }}</h3>

					<div class="text-center home-card-text">
						{{ block.Body }}
					</div>
				</div>
			</div>
			<div class="limitWidth mb-10">
				<v-carousel :show-arrows="$vuetify.breakpoint.smAndDown" next-icon="fas fa-angle-right"
					prev-icon="fas fa-angle-left" :hide-delimiters="$vuetify.breakpoint.smAndDown" height="400">
					<v-carousel-item v-for="(block, index) in contentBlockComeFunziona" :key="block.Title">
						<v-sheet color="#4B539B" class="pa-5" height="100%" tile>
							<div class="white--text text-center nutriyou-h3 mb-4" style="font-size:1.4rem">
								Come funziona Nutriyou
							</div>
							<div class="nutriyou-h3 mb-3 text-center" style="color: #78D2E6; font-size: 1rem;">
								Step {{ index+1 }}:
							</div>
							<div class="text-center my-5">
								<v-img width="200" contain :src="block.ImageUrl" style="display: inline-block"> </v-img>
							</div>
							<div class="white--text text-center home-card-text CardTitleSize">
								{{ block.Title }}
							</div>
						</v-sheet>
					</v-carousel-item>
				</v-carousel>
			</div>

			<div class="mb-8 limitWidth">
				<ReviewCarosel></ReviewCarosel>
			</div>

			<div class="mb-5">
				<h2 class="nutriyou-h3 text-center primary--text">Dal nostro Blog</h2>
				<StaticContentGrid class="my-5 limitWidth" :ContentIds="[1,2,3]">
				</StaticContentGrid>
			</div>
			<v-container class="limitWidth">
				<v-row>
					<v-col cols="4" md="5">
						<v-img src="https://nutriyou.blob.core.windows.net/nutriyouweb/Illustrations/food/panino.png"
							max-height="120" contain></v-img>
					</v-col>
					<v-col cols="8" md="7">
						<div class="mb-3">
							Sei un <span class="font-weight-bold">Dietista, Dietologo</span> o <span
								class="font-weight-bold">Biologo</span>
							Nutrizionista?
						</div>
						<div class="mb-3 font-weight-normal">
							<v-btn large color="primary" to="/entra-nel-team">Entra nel Team</v-btn>
						</div>

					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import PatientHome from "@/views/Patients/components/PatientHome";
import StaticContentGrid from "./Blog/StaticContentGrid.vue";
import ReviewCarosel from "./Reviews/ReviewCarosel";
export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	name: "Home",
	components: {
		PatientHome,
		StaticContentGrid,
		ReviewCarosel
	},
	data() {
		return {
			currentEvent: null,
			contentBlock: [
				{
					src: "https://nutriyou.blob.core.windows.net/nutriyouweb/Illustrations/homeicons/euro.png",
					Title: "Nessuna sorpresa",
					Body: "Ogni visita ha un costo fisso di 55€ e l'incontro conoscitivo iniziale è gratuito.",
				},
				{
					src: "https://nutriyou.blob.core.windows.net/nutriyouweb/Illustrations/homeicons/apple.png",
					Title: "Specifico per te",
					Body: `Il nostro algoritmo di matching ti assegna il nutrizionista più adatto in base alle tue caratteristiche e ai tuoi bisogni.
					I professionisti presenti su Nutriyou si occupano di oltre 11 ambiti di specializzazione`,
				},
				{
					src: "https://nutriyou.blob.core.windows.net/nutriyouweb/Illustrations/homeicons/person.png",
					Title: "Abilitati e selezionati",
					Body: `Tutti i nutrizionisti sul sito sono dottoresse e dottori abilitati che hanno superato i nostri criteri di selezione.
					Nella pagina Come funziona ti spieghiamo quali sono.`,
				},
			],

			contentBlockComeFunziona: [
				{
					Title: "Compila il questionario",
					ImageUrl:
						"https://nutriyou.blob.core.windows.net/nutriyouweb/Illustrations/step1.png",
				},

				{
					Title:
						"L’algoritmo di Nutriyou ti assegna il nutrizionista su misura per te",
					ImageUrl:
						"https://nutriyou.blob.core.windows.net/nutriyouweb/Illustrations/step2.png",
				},
				{
					Title:
						"Con l’incontro conoscitivo GRATUITO capisci se è la persona giusta",
					ImageUrl:
						"https://nutriyou.blob.core.windows.net/nutriyouweb/Illustrations/step3.png",
				},
				{
					Title: "Inizia il tuo percorso nutrizionale!",
					ImageUrl:
						"https://nutriyou.blob.core.windows.net/nutriyouweb/Illustrations/step4.png",
				},
			],
		};
	},

	computed: {
		...mapGetters(["SasToken", "isUserAuthenticated",
			"isUserInRole", "UserProfile"]),
	},
	mounted() {
		const tallyScript = document.createElement("script");
		tallyScript.setAttribute("src", "https://tally.so/widgets/embed.js");
		document.head.appendChild(tallyScript);
	},

	methods: {
	},
};
</script>
<i18n>
{
	"it":{
		"pageTitle": "Nutriyou",
		"pageMetaDescription": " Il nutrizionista online su misura per te"
	}
}
</i18n>
<style lang="scss" scoped>
.limitWidth {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
</style>
