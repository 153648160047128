<template>
	<v-container>
		<v-row>
			<v-col cols="12" md="10" class="offset-md-1">
				<StaticContentViewer
					:ContentId="ContentId"
					:key="ContentId"

				></StaticContentViewer>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
// src\views\Admin\Content\components\StaticContent\Viewer.vue
import StaticContentViewer from "@/views/Admin/Content/components/StaticContent/Viewer";

export default {
	name: "StaticContentPage",
	components: { StaticContentViewer },
	props: { ContentId: Number },
	data: () => ({}),
	async mounted() {
		this.$log.info("StaticContent View Mounted");
	},
};
</script>
