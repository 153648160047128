<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
      </template>
		</v-breadcrumbs>
	<h1 class="nutriyou-h1">Nutriyou Blog</h1>

    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" v-for="content in StaticContentList" :key="content.ContentId">
          <v-card
            :to="'./Blog/' + content.FriendlyUrl">
            <v-card-text class="text-center">
              <v-img v-if="content.ImageFilePath"
                     class="rounded"
                     :src="content.ImageFilePath[400]"></v-img>
            </v-card-text>
            <v-card-title >
              <span class="truncate">   {{ content.Title }}</span>

            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
	</div>
</template>

<script>
import CrudClient from "@/services/CrudClient";

export default {
	name: "StaticContentPage",
	data: () => ({
		StaticContentList: [],
		LoadingData: false,
		breadcrumbsItems: [
			{
				text: "Home",
				disabled: false,
				exact: true,
				to: "/",
			}
		],
	}),
	created() {
		this.StaticContentService = new CrudClient("StaticContent");
	},
	async mounted() {
		this.breadcrumbsItems.push(
			{
				text: this.$t("common.blog"),
				disabled: true,
				exact: true,
				to: "/Blog",
			},
		);

		await this.LoadStaticContent();
	},
	methods: {
		async LoadStaticContent() {
			try {
				this.LoadingData = true;

				const response = await this.StaticContentService.GetPaged(
					{ limit: 0, skip: 0, orderBy: "ContentId", fields: "ContentId, Title, ImageFilePath, FriendlyUrl", urlPostFix: "Public" }

				);
				this.StaticContentList = response.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError.LoadStaticContent") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	}
};
</script>
