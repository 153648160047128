<template>
    <v-dialog :value="value" max-width="300" persistent @keydown.esc="$emit('input', false)">
        <v-card v-if="availableInterval">
            <v-card-title>Slot Disponibile</v-card-title>
            <v-card-text>
              <span class="font-weight-bold text-subtitle-2" style="font-size: 0.8rem;">
              {{ new Date(availableInterval.IntervalDate).toLocaleDateString("it-IT") }}</span>
              <br />
              {{ availableInterval.IntervalStart.slice(0, 5) }} -
              {{ availableInterval.IntervalEnd.slice(0, 5) }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="DeleteAvailableInterval" :disabled="pastDayEvent" :loading="deletingInterval"><v-icon left>fas fa-trash</v-icon>{{$t("common.delete")}}</v-btn>
              <v-btn color="primary"  @click="$emit('input', false)"><v-icon left>fas fa-times</v-icon>{{$t("common.close")}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import CrudClient from "@/services/CrudClient";
import { mapActions } from "vuex";
import { getCETDate, getDateOnlyString } from "./dateUtils"

export default {
	data() {
		return {
			deletingInterval: false,
		}
	},
	props: {
		value: {
			type: Boolean
		},
		availableInterval: {
			type: Object
		}
	},
	computed: {
		pastDayEvent() {
			const date = getCETDate();
			const dateOnly = getDateOnlyString(date);
			return dateOnly > this.availableInterval.IntervalDate;
		}
	},
	created() {
		this.DailyAvailabilitiesService = new CrudClient("CurrentNutritionist/DailyAvailabilities");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async DeleteAvailableInterval() {
			try {
				this.deletingInterval = true;
				await this.DailyAvailabilitiesService.Delete(this.availableInterval.IntervalId);
				this.$emit("input", false);
				this.$emit("IntervalDeleted", this.availableInterval.Id);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("cannotDeleteData") });
			} finally {
				this.deletingInterval = false;
			}
		}
	}

}
</script>
