<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
      </template>
		</v-breadcrumbs>

		<v-card class="elevation-5 pb-4">
			<v-toolbar flat class="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("nutriyou.tags") }}</v-toolbar-title>
			</v-toolbar>

			<confirm ref="confirm"></confirm>

			<div style="height: 0px; position: relative">
				<v-btn absolute @click="OpenNewDialog" dark fab top right color="green">
					<v-icon>add</v-icon>
				</v-btn>
			</div>

			<skeleton-table v-if="LoadingData" :actions="2"></skeleton-table>

			<v-list v-else>
				<v-list-item
					v-for="item in items"
					:key="item.SkillId"
					class="elevateOnHover"
				>
					<v-list-item-content>
						<v-list-item-title v-text="item.Name"></v-list-item-title>
					</v-list-item-content>

					<v-list-item-action class="pr-4">
						<v-list-item-title>
							<v-chip
								label
								text-color="white"
								class="width-100p"
								:class="{
									secondary: item.IsActive,
									error: !item.IsActive,
								}"
							>
								<span v-if="item.IsActive">{{ $t("common.isItemActive.true") }}</span>
								<span v-if="!item.IsActive">{{ $t("common.isItemActive.false") }}</span>
							</v-chip>
						</v-list-item-title>
					</v-list-item-action>

					<v-list-item-action>
						<v-btn icon ripple @click="OpenEditDialog(item.SkillId)">
							<v-icon color="primary">fas fa-fw fa-edit</v-icon>
						</v-btn>
					</v-list-item-action>

					<v-list-item-action>
						<v-btn icon ripple @click="DeleteSkill(item.SkillId)">
							<v-icon color="error">fas fa-fw fa-trash-alt</v-icon>
						</v-btn>
					</v-list-item-action>
				</v-list-item>
			</v-list>
			<div class="text-center">
				<v-pagination
					v-model="currentPage"
					:length="itemsPaging.TotalPages"
					:total-visible="5"
				></v-pagination>
			</div>

			<v-alert
				:value="!LoadingData && items.length === 0"
				class="pa-4 ma-4"
				type="info"
				>{{ $t("common.noItems") }}</v-alert
			>
		</v-card>

		<v-dialog
			v-model="dialogData.ShowDialog"
			@keydown.esc="CloseDialog"
			hide-overlay
			no-click-animation
			max-width="70%"
		>
			<v-overlay :value="formSkillDetails.FormLoading">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-card>
				<v-toolbar dark color="primary">
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ dialogData.Title }}</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
				<div class="detailsDialogContent">
					<SimpleFormCard
						:cardProps="{ flat: true, class: 'pa-1 elevation-3' }"
						ref="formSkillDetails"
						:formContent="formSkillDetails"
						:initialValues="contentDetails"
						:metadata="metadata"
						:metadataLoaded="metadataLoaded"
						:mode="dialogData.Mode"
						@submitForm="AddOrSave"
						:showCancelButton="false"
						:locali18n="$i18n"
					></SimpleFormCard>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import metadata from "@/mixins/metadata";
import ConfirmTool from "@/components/Shared/Common/Confirm";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";

export default {
	metaInfo() {
		return {
			title: this.$t("nutriyou.tags"),
			meta: [{ name: "description", content: this.$t("nutriyou.tags") }],
		};
	},
	name: "EditTags",
	$_veeValidate: { validator: "EditTags" },
	components: {
		confirm: ConfirmTool,
		SimpleFormCard,
	},
	mixins: [metadata],
	data() {
		return {
			TagsService: new CrudClient("SGP/Tags"),
			LoadingData: false,
			items: [],
			itemsPaging: [], // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 10,
			contentDetails: {},
			dialogData: {
				ShowDialog: false,
				Mode: 1, // 1 - New, 2 - Edit
				Name: "",
				SkillId: null,
				IsActive: false,
			},

			formSkillDetails: new CrudFormData("formSkillDetails", [
				"Name",
				{
					FieldName: "IsActive",
					type: "v-checkbox",
				},
			]),

			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
		};
	},

	watch: {
		"dialogData.ShowDialog": function (val) {
			!val && this.clearDialogForm();
		},
		async currentPage() {
			await this.LoadSkillsList();
		},
	},

	async mounted() {
		this.$log.info("Skills View Mounted");
		await this.LoadSkillsList();
		await this.LoadMetadata(this.TagsService);
		this.breadcrumbsItems.push(
			{
				text: "Area Admin",
				disabled: false,
				exact: true,
				to: "/Admin",
			},
			{
				text: this.$t("nutriyou.tags"),
				disabled: true,
				exact: true,
				to: "/Admin/Tags",
			}
		);
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadSkillsList() {
			this.LoadingData = true;
			try {
				const res = await this.TagsService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "SkillId, Name, IsActive",
					orderBy: "Name",
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
				this.currentPage = res.Paging.CurrentPageIndex + 1;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},

		async OpenNewDialog() {
			this.dialogData.Mode = 1;
			this.dialogData.Title = this.$t("newSkill");
			this.dialogData.ShowDialog = true;
		},

		async OpenEditDialog(SkillId) {
			this.dialogData.Mode = 2;
			this.dialogData.Title = this.$t("editSkill");
			this.dialogData.ShowDialog = true;
			this.dialogData.SkillId = SkillId;

			await this.LoadContentDetails();
		},

		async LoadContentDetails() {
			try {
				this.formSkillDetails.FormLoading = true;
				const res = await this.TagsService.Get(this.dialogData.SkillId);
				this.contentDetails = res;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.formSkillDetails.FormLoading = false;
			}
		},

		CloseDialog() {
			this.dialogData.ShowDialog = false;
		},

		clearDialogForm() {
			this.$log.info("Clear form data");
			if (this.$refs.formSkillDetails) {
				this.$refs.formSkillDetails.ResetForm();
			}
		},

		async AddOrSave(value) {
			this.formSkillDetails.FormSendingData = true;
			try {
				if (this.dialogData.Mode === 1) {
					await this.TagsService.Add(value);
					this.snackSuccess({ Text: this.$t("common.success.addContent") });
				} else {
					await this.TagsService.Patch(this.dialogData.SkillId, value);
					this.snackSuccess({ Text: this.$t("common.success.updateContent") });
				}
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.formSkillDetails.FormSendingData = false;
				this.CloseDialog();
				await this.LoadSkillsList();
			}
		},

		async DeleteSkill(skillId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete"),
					{ color: "error" }
				))
			) {
				return;
			}
			this.LoadingData = true;
			try {
				await this.TagsService.Delete(skillId);
				this.snackSuccess({ Text: this.$t("tagAdded") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotDelete") });
			} finally {
				this.LoadingData = false;
			}
			await this.LoadSkillsList();
		},
	},
};
</script>
<style lang="sass" scoped>
</style>
<i18n>
{
	"it": {
        "pageMetaDescription": "Tag Utenti",
        "newSkill": "Nuovo tag",
        "agencySkillDelete": "Tag eliminato con successo",
        "agencySkillDeleteError": "Impossibile eliminare il tag",
        "editSkill": "Modifica tag",
        "formSkillDetails": {
			"Name": "Tag",
			"IsActive" : "Attivo?"
		},
		"tagAdded": "Tag creato!"
    }
}
</i18n>
