import Vue from "vue"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"

import it from "vuetify/es5/locale/it"
// import "@fortawesome/fontawesome-pro/css/all.css"
import "material-design-icons-iconfont/dist/material-design-icons.css"
// Helpers
import colors from "vuetify/es5/util/colors"

Vue.use(Vuetify)

const opts = {
	lang: {
		locales: {
			it
		},
		current: "it"
	},
	icons: {
		iconfont: "fa"
	},
	theme: {
		dark: false,
		themes: {
			// Note: when changed, sync with variables.scss
			light: {
				success: colors.green.base,
				primary: "#1E2882",
				secondary: "#a0d264", // "#699b30", // "#78d2e6",
				nyrose: "#F06464",
				nyroseLight: "#FCE0E0",
				nyblueBackgroud: "#E4F6FA",
				nyblue: "#78D2E6",
				nyGreen: "#B3DB83",
				nyGreenAlt: "#A0D264",
				nyGreenLight: "#D9EDC1",
				nyLightYellow: "#FFF7E5",
				nyYellow: "#FFB800"
			}
		}
	}
}

export default new Vuetify(opts)
