<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems" class="pl-3">
      <template v-slot:divider>
        <v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
      </template>
		</v-breadcrumbs>

		<h1 class="nutriyou-h1">{{ $t("nutriyou.nutritionists") }}</h1>
		<v-list>
			<v-list-item v-for="item in items" :key="item.UserId"
      :class="item.IsActive? 'nyGreenLight': 'grey lighten-2'">
				<v-list-item-avatar>
					<v-img :height="100" :width="100" contain :src="item.ImageFileUrl"></v-img>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title> {{
						item.ApplicationUser.DisplayName
					}}</v-list-item-title>
					<v-list-item-subtitle>{{
						item.ApplicationUser.ProfessionalTitle
					}}</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-action>
					<v-btn icon color="primary" :to="'./NutritionistProfile/' + item.UserId">
						<v-icon>fas fa-fw fa-user</v-icon></v-btn
					>
				</v-list-item-action>

				<v-list-item-action>
					<v-btn icon color="primary" :to="'/Users/EditUser/' + item.UserId">
						<v-icon>fas fa-fw fa-user-cog</v-icon></v-btn
					>
				</v-list-item-action>

				<v-list-item-action v-if="item.ProfilePageUrl">
					<v-btn icon color="primary" :to="'/Nutritionists/' + item.ProfilePageUrl">
						<v-icon>fas fa-fw fa-external-link-alt</v-icon></v-btn
					>
				</v-list-item-action>
			</v-list-item>
		</v-list>
		<div class="text-center">
			<v-pagination
				v-if="itemsPaging"
				v-model="currentPage"
				:length="itemsPaging.TotalPages"
				:total-visible="5"
			></v-pagination>
		</div>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";

import { mapActions } from "vuex";
export default {
	metaInfo() {
		return {
			title: "",
		};
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Area Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
			],
			LoadingData: false,
			items: [],
			itemsPaging: null,
			currentPage: 1,
			pageSize: 20,
		};
	},
	created() {
		this.NutritionistsService = new CrudClient("Nutritionists");
	},

	watch: {
		async currentPage() {
			await this.LoadNutritionists();
		},
	},

	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.nutritionists"),
			disabled: true,
			exact: true,
			to: "/Admin/Nutritionists",
		});

		await this.LoadNutritionists();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadNutritionists() {
			try {
				this.LoadingData = true;
				const res = await this.NutritionistsService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "*",
					// filter: filter,
					orderBy: "CreateDate:desc"
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>
