<template>
<div>
  <v-breadcrumbs :items="breadcrumbsItems">
    <template v-slot:divider>
      <v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
  <h1 class="nutriyou-h1">{{ $t("pageTitle") }}</h1>
<!--  <v-toolbar>-->
<!--  </v-toolbar>-->
<!--{{PagedResults}}-->
<skeleton-table v-if="LoadingData" :columns="3" :lines="10"></skeleton-table>
  <v-container fluid v-if="!LoadingData && PagedResults">

  <v-row v-for="result in PagedResults.Data" :key="result.BookingId"
  >
    <v-col>
    BookingId	: {{ result.BookingId}} 	<nutriYouOrderStatusChip :StatusId="result.BookingStatusId"></nutriYouOrderStatusChip>
    <br />
    Data Visita: {{ result.BookingTime | formatDateTime}} <br />
    Data Pagamento: {{ result.PaymentTime | formatDateTime}} <br />
    Prezzo: {{ result.Price | formatCurrency }}, Sconto: {{ result.Discount | formatCurrency }}, Coupon: {{ result.Coupon }} <br />
    Totale Pagamento: {{ result.TotalPaymentAmount | formatCurrency }}
    <br />
    {{ IsInvoiced ? "Fatturato" : "Da fatturare"}}
    <br />
    {{ IsPaidToNutritionist ? "Non pagato al nutrizionista" : "Da pagare al nutrizionista"}}
    </v-col>
    <v-col>
      <v-card>
        <v-card-title>Dati fatturazione Nutrizionista</v-card-title>
          <v-card-text>
          {{result.NutritionistProfile.DisplayName}} <br />
          {{result.NutritionistProfile.Email}} <br />

          <v-alert type="error" v-if="!result.NutritionistBillingProfile">
            Dati di fatturazione non trovati!
          </v-alert>
            <div v-else>
              Nome: {{ result.NutritionistBillingProfile.BillingProfile.RagioneSociale }} <br />
              Partita IVA: {{ result.NutritionistBillingProfile.BillingProfile.PartitaIva }} <br />
              Codice Fiscale: {{ result.NutritionistBillingProfile.BillingProfile.CodiceFiscale }} <br />
              Indirizzo:  {{ result.NutritionistBillingProfile.BillingProfile.Address }} <br />
              CAP: {{ result.NutritionistBillingProfile.BillingProfile.PostalCode }} <br />
              Comune: {{ result.NutritionistBillingProfile.ItalianCityName }} ({{ result.NutritionistBillingProfile.ItalianCityProvinciaAbbreviation }})<br />

              IBAN: {{ result.NutritionistBillingProfile.BillingProfile.Iban }} <br />
            </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-title>
          Dati fatturazione Paziente
        </v-card-title>
        <v-card-text>
          {{result.PatientProfile.DisplayName}}  <br />
          {{result.PatientProfile.Email}}
          <br />

          <v-alert type="error" v-if="!result.PatientBillingProfile">
            Dati di fatturazione non trovati!
          </v-alert>
          <div v-else>
           Nome: {{ result.PatientBillingProfile.BillingProfile.RagioneSociale }} <br />
           Codice Fiscale: {{ result.PatientBillingProfile.BillingProfile.CodiceFiscale }} <br />
           Indirizzo:  {{ result.PatientBillingProfile.BillingProfile.Address }} <br />
           CAP: {{ result.PatientBillingProfile.BillingProfile.PostalCode }} <br />
           Comune: {{ result.PatientBillingProfile.ItalianCityName }} ({{ result.PatientBillingProfile.ItalianCityProvinciaAbbreviation }})
          </div>

        </v-card-text>
      </v-card>

    </v-col>
  </v-row>
  </v-container>
  <v-pagination v-model="currentPage" v-if="PagedResults"
        :length="PagedResults.Paging.TotalPages"
        :total-visible="5"
  ></v-pagination>

</div>
</template>
<script>
import CrudClient from "@/services/CrudClient";
import nutriYouOrderStatusChip from "@/components/NutriYou/nutriYouOrderStatusChip.vue";

export default {
	name: "BookingsToInvoice",
	components: { nutriYouOrderStatusChip },
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Area Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
				{
					text: this.$t("nutriyou.invoicing"),
					disabled: false,
					exact: true,
					to: "/Admin/Invoicing",
				},
				{
					text: this.$t("pageTitle"),
					disabled: true,
					exact: true,
					to: "/Admin/Invoicing/BookingsToInvoice",
				}
			],

			PagedResults: null,
			currentPage: 1,
			pageSize: 10,
			loadingData: false
		}
	},
	watch: {
		currentPage: async function(val) {
			await this.LoadData();
		}
	},
	created() {
		this.InvoicingService = new CrudClient("Invoicing");
	},
	async mounted() {
		this.currentPage = 1;
		await this.LoadData();
	},
	methods: {
		async LoadData() {
			try {
				this.LoadingData = true;
				this.PagedResults = await this.InvoicingService.Get(null, `Bookings?limit=${this.pageSize}&skip=${(this.currentPage - 1) * this.pageSize}`);
			} finally {
				this.LoadingData = false;
			}
		}
	}
}
</script>
<i18n>
{
  "it": {
      "pageTitle": "Visite da Fatturare"
  }
}
</i18n>
