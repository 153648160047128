<template>
	<div class="mt-5">
		<v-switch label="Abilita Esportazione del Calendario" v-model="ExportCalendar"
			@change="ExportCalendarChanged"></v-switch>
		<v-text-field v-if="ExportCalendar" style="width:300px" ref="calendarUrl"
			label="Url per importare il calendario" readonly :value="CalendarUrl" append-icon="far fa-copy"
			@click:append="copyCalendarUrl"></v-text-field>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient";
import api from "@/services/api";

export default {
	data() {
		return {
			CalendarUrl: null,
			ExportCalendar: false,
			CalendarKey: null,
		}
	},
	created() {
		this.NutritionistsService = new CrudClient("CurrentNutritionist/Nutritionist");
	},
	mounted() {
		this.LoadNutritionistData();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadNutritionistData() {
			const res = await this.NutritionistsService.GetSelectedFields(null, "ExportCalendar, CalendarKey");

			this.ExportCalendar = res.ExportCalendar;
			this.CalendarKey = res.CalendarKey;
			this.CalendarUrl = `${api.url}CalendarExport/${this.CalendarKey}`;
		},

		async copyCalendarUrl() {
			await navigator.clipboard.writeText(this.CalendarUrl)
		},
		async ExportCalendarChanged() {
			try {
				const dto = {
					ExportCalendar: this.ExportCalendar
				}
				await this.NutritionistsService.Patch(null, dto, false);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			}
		},
	}
}
</script>
