<template>
	<div class="mb-4">
		<v-container fluid class="px-0">
      <v-row>
        <v-col cols="3" class="text-center">
          <BookingDateTimeDisplay :date="booking.BookingTime" />
        </v-col>
        <v-col cols="9" >
          <div class="text-center mb-3">
            <div class="poppins-regular text-uppercase mb-5" style="font-size: 1.2rem" >
              {{ booking.BookingType.Name }}
            </div>

            <v-btn v-if="booking.BookingStatusId === BookingStatusIdEnum.BOOKED"
                   class="text-capitalize" color="nyrose" dark
                   :large="$vuetify.breakpoint.smAndUp"
                   :small="$vuetify.breakpoint.xsOnly"
                   :to="'/Patients/Agenda/' + booking.BookingId +  '/VideoRoom'"
            >
              <v-icon left v-if="$vuetify.breakpoint.smAndUp">fas fa-video</v-icon>
              Vai alla Videochiamata
            </v-btn>

              <StripeCheckoutButton v-if="booking.BookingStatusId === BookingStatusIdEnum.WAITINGFORPAYMENT"
                                    :booking="booking" color="primary" class="text-capitalize"
                                    large
                                    @RequireBillingProfile="RequireBillingProfile"
                                    @RequirePrivacyContract="RequirePrivacyContract"
                                    ref="stripeButton">
              </StripeCheckoutButton>

              <AddEditBillingProfile
                v-model="showAddBillingProfile"
                :mode="1"
                @updated="OnBillingProfileUpdated"
                Header="Mancano i dati per la fattura">
              </AddEditBillingProfile>

              <PrivacyContractsDialog v-model="showPrivacyContracts"
                                      @contractsAccepted="OnPrivacyContractAccepted" />

            </div>
        </v-col>
      </v-row>

		</v-container>

	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
// import nutriYouOrderStatusChip from "@/components/NutriYou/nutriYouOrderStatusChip";
// import nutriYouBookingTypeChip from "@/components/NutriYou/nutriYouBookingTypeChip";
import StripeCheckoutButton from "./StripeCheckoutButton";
import { BookingStatusIdEnum } from "@/components/NutriYou/nutriYouBookingStatusEnum";

import AddEditBillingProfile from "@/components/Account/AddEditBillingProfile";
import moment from "moment";
import PrivacyContractsDialog from "@/views/Patients/components/PrivacyContractsDialog.vue";
import BookingDateTimeDisplay from "@/views/Patients/components/BookingDateTimeDisplay.vue";

export default {
	components: {
		BookingDateTimeDisplay,
		PrivacyContractsDialog,
		StripeCheckoutButton,
		AddEditBillingProfile,
	},
	props: {
		booking: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showModifyDialog: false,
			newDataTime: null,
			showAddBillingProfile: false,
			showPrivacyContracts: false
		};
	},
	computed: {
		FormattedBookingTime() {
			moment.locale("it");

			// if this.booking.BookingTime is within 3 days time
			const now = moment();
			const bookingTime = moment(this.booking.BookingTime);
			if (bookingTime.diff(now, "days") <= 3) {
				// your logic here
				return moment(this.booking.BookingTime).calendar();
			}

			return moment(this.booking.BookingTime).format("LLLL");
		}
	},
	created() {
		this.BookingStatusIdEnum = BookingStatusIdEnum;
		this.NutritionistsService = new CrudClient("Nutritionists/Public");
	},
	async mounted() {

	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		RequireBillingProfile() {
			this.showAddBillingProfile = true;
		},

		OnPrivacyContractAccepted() {
			this.$refs.stripeButton.redirectToStripe();
		},

		RequirePrivacyContract() {
			this.showPrivacyContracts = true;
		},

		OnBillingProfileUpdated() {
			this.showAddBillingProfile = false;

			this.$refs.stripeButton.redirectToStripe();
		},
	},
};
</script>
<i18n>
{
	"it":{
		"CancelBooking": "Annulla l'appuntamento",
		"CompleteBooking": "Completa"
	}
}
</i18n>
