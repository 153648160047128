<template>
	<v-card>
		<h1 class="nutriyou-h1">{{ $t("pageTitle") }}</h1>
		<vue-pivottable-ui ref="pivottable"
			:data="items"
			:rows="['Nutritionist DisplayName', 'Booking Status']"
			:cols="['Booking Date Year', 'Booking Date Month']"

      :appliedFilter="[ {'Booking Status': { Booked: true, Cancelled: false, 'Waiting for Payment': false}}]"
    >
		</vue-pivottable-ui>

	</v-card>
</template>
<script>

import { mapActions } from "vuex";
import { VuePivottableUi } from "vue-pivottable"
import "vue-pivottable/dist/vue-pivottable.css"
import CrudClient from "@/services/CrudClient/";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageTitle") }],
		};
	},
	components: {
		VuePivottableUi
	},
	data() {
		return {
			LoadingData: false,
			items: null
		}
	},
	created() {
		this.BookingsService = new CrudClient("Reports/PeriodStats");
	},
	async mounted() {
		await this.LoadBookings();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadBookings() {
			try {
				this.LoadingData = true;
				this.items = await this.BookingsService.Get("PivotView");
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	}
}
</script>
<i18n>
{
	"it":{
		"pageTitle": "Data Pivot View"
	}
}
</i18n>
