<template>
	<div class="pa-1">
		<v-avatar :size="size" color="purple" :tile="false" v-if="showAvatarIcon">
			<v-img
				v-if="profile.ImageFilePath"
				:src="profile.ImageFilePath"
			></v-img>
			<span class="avatarText noSelect" :style="{ fontSize: size * 0.5 + 'px' }" v-else>{{
				profile.DisplayName | getInitial
			}}</span>
		</v-avatar>
		<div style="display: inline-block;" v-if="showDisplayName" :class="textClass">{{
			profile.DisplayName
		}}
		<span v-if="showEmailIdentifier" class="grey--text" style="font-size:13px">{{ profile.EmailIdentifier }}</span>
		</div>
	</div>
</template>
<script>
export default {
	name: "UserAvatarFullname",
	props: {
		profile: Object,
		size: {
			type: Number,
			default: 36,
		},
		textClass: {
			type: String,
			default: "subheading ml-2",
		},
		showDisplayName: {
			type: Boolean,
			default: true,
		},
		showAvatarIcon: {
			type: Boolean,
			default: true,
		},
		showEmailIdentifier: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
<style>
.avatarText {
	color: #FFFFFF;
	font-size: 24px;
}
</style>
