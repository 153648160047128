<template>
	<div class="chatWindow" v-visibility-change="visibilityChange" @drop.stop.prevent="dropFile"
		@dragover.prevent="dragOverHandler" @dragenter="setDragOverlay" @dragleave="setDragOverlay"
		style="overflow-y: hidden">
		<v-overlay absolute :value="dragOverlay > 0"></v-overlay>
		<div class="overflow-y-auto pa-3 text-center" v-scroll:#scrollArea="onScroll" ref="scrollArea" id="scrollArea"
			:class="{ noSelect: $vuetify.breakpoint.xs }">
			<v-overlay :value="loadingOverlay">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-progress-circular v-if="loadingMessages" indeterminate color="primary"></v-progress-circular>

			<v-alert type="info" dark v-if="loadedMessages && messagesView.length === 0">
				<span v-if="isUserInRole('Patient')">{{ $t("noMessagesPatient") }}</span>
				<span v-else-if="isUserInRole('Nutritionist')">{{
					$t("noMessagesNutritionist")
					}}</span>
				<span v-else>{{ $t("noMessages") }}</span>
			</v-alert>

      <div v-for="(message, index) in messagesView" :key="message.sid" :class="
					message.author.toLowerCase() === UserProfile.Id.toLowerCase() ? 'messageSent' : 'messageReceived'
				">
				<!-- Show author Avatar whenever the author is changed -->
				<div v-if="index === 0 || messagesView[index - 1].author !== message.author">
					<user-avatar-fullname :showDisplayName="false"
            :profile="message.author.toLowerCase() === UserProfile.Id.toLowerCase() ? UserProfile : CurrentConversationView"
						v-if="CurrentConversationView">
          </user-avatar-fullname>
					<v-avatar v-else size="36" color="grey"></v-avatar>
				</div>

				<Message :message="message"
               :userId="UserProfile.Id"
               :lastSeenMessageId="participantLastSeenMessageId">
        </Message>

			</div>

      <div class="amber accent-2 pa-2 rounded" v-if="showPatientFirstMessageInfo"
           style="font-size: 0.8rem;line-height: 1rem;">

        <span class="poppins-regular grey--text text--darken-2">
          Il tuo nutrizionista ti risponderà nelle prossime ore.
          <br />
          Ti arriverà una notifica via e-mail <v-icon small>fas fa-envelope</v-icon>
        </span>
      </div>
      <UploadingFileWidget v-for="(file, n) in uploadQueue" :key="n" :file="file"></UploadingFileWidget>

			<!-- Typing indicator -->
			<div class="green lighten-4 messageBox pa-1 mb-2" v-if="typingParticipant !== null">
				<vue-loaders-ball-pulse scale="0.6" color="black"></vue-loaders-ball-pulse>
			</div>
			<v-fab-transition>
				<v-btn absolute small fab top right color="blue-grey lighten-4" class="btn-scrollToBottom"
					@click="scrollToBottom" v-if="showScrollButton">
					<v-icon color="blue-grey darken-1">fas fa-chevron-down</v-icon>
				</v-btn>
			</v-fab-transition>
		</div>
		<ChatMessageInput
      :CurrentConversationView="CurrentConversationView"
      :uploadingFiles="uploadingFiles"
			@uploadFiles="UploadFiles"
			@filesSelected="AddToConversationSelectedFiles">
		</ChatMessageInput>

	</div>
</template>

<script>
// @/views/Admin/Chat/widgets
import Message from "./Message";
import UploadingFileWidget from "./Message/Widgets/UploadingFile";

import { mapGetters, mapActions } from "vuex";
import visibility from "vue-visibility-change";
import UserAvatarFullname from "@/components/Shared/UI/UserAvatarFullname";
import CrudClient from "@/services/CrudClient/";
import ChatMessageInput from "./ChatMessageInput"

import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallPulse from "vue-loaders/dist/loaders/ball-pulse";

export default {
	name: "chatWindow",
	components: {
		"vue-loaders-ball-pulse": VueLoadersBallPulse.component,
		Message,
		UploadingFileWidget,
		UserAvatarFullname,
		ChatMessageInput
	},
	props: {

	},
	data() {
		return {
			chatService: new CrudClient("Chat"),
			firstItemOfLastBatch: null,
			hasPrevPage: false,
			isPageVisible: false,
			loadingMessages: false,
			loadedMessages: false,
			participantLastSeenMessageId: null,
			messages: [],

			typingParticipant: null,
			loadingOverlay: false,
			dragOverlay: 0,
			showScrollButton: false,

			uploadingFiles: false,
			maxFileSize: 10,

			listenersUserId: null,
			showPatientFirstMessageInfo: false
		};
	},
	computed: {
		...mapGetters([
			"TwilioChatClient",
			"conversationUploadQueue",
			"isUserInRole",
			"conversationSelectedFiles",
			"CombinedConversationView",
			"currentConversationUserId",
			"UserProfile"
		]),

		uploadQueue() {
			if (!this.ConversationSid) return null;

			return this.conversationUploadQueue[this.ConversationSid];
		},

		messagesView() {
			// reserved for future view filters
			return this.messages;
		},

		CurrentConversationView() {
			if (this.currentConversationUserId) {
				return this.CombinedConversationView.find((c) => c.UserId === this.currentConversationUserId);
			} else {
				return null;
			}
		},

		ConversationSid() {
			return this.CurrentConversationView.ConversationSid;
		}
	},
	// Notes: ChatWindow has :key property forcing rendering form 0
	// watch currentConversationUserId does not arrive with the old value, since it is destroyed and set up again
	// in order to remove listeners we save the last known userId in listenersUserId, otherwise at beforeDestroy comes only the new value

	watch: {
		currentConversationUserId: {
			immediate: true,
			async handler(newValue) {
				if (newValue) {
					this.$log.info("Setting up event listeners watch:" + newValue);

					const newConversationView = this.CombinedConversationView
						.find((c) => c.UserId.toLowerCase() === newValue.toLowerCase());

					if (newConversationView.ConversationSid) {
						this.listenersUserId = newValue;
						this.addListenersToConversation(newConversationView.TwilioConversation.conversation);

						const participant = await newConversationView.TwilioConversation.conversation.getParticipantByIdentity(this.currentConversationUserId);
						this.participantLastSeenMessageId = participant.lastReadMessageIndex;
					}
				}
			}
		},
		"CurrentConversationView.ConversationSid": {
			handler(newValue, oldValue) {
				this.$log.debug("CurrentConversationView.ConversationSid watch");
				if (oldValue === null && newValue) {
					this.$log.debug("Setting up event listeners watch CurrentConversationView.ConversationSid:" + newValue);
					this.listenersUserId = this.CurrentConversationView.UserId;
					this.addListenersToConversation(this.CurrentConversationView.TwilioConversation.conversation);
				}
			}
		}
	},
	methods: {
		...mapActions([
			"snackSuccess",
			"snackError",
			"SortConversationsByDate",
			"AddConversationUploadQueue",
			"SetConversationUploadQueueProgress",
			"SetConversationUploadQueueComplete",
			"InitConversationSelectedFiles",
			"AddConversationSelectedFiles",
			"ClearConversationSelectedFiles"
		]),

		dragOverHandler(e) {
			e.dataTransfer.dropEffect = "move";
		},

		setDragOverlay(e) {
			if (e.type === "dragenter") {
				this.dragOverlay++;
			}
			if (e.type === "dragleave") {
				this.dragOverlay--;
			}
		},

		dropFile(e) {
			this.dragOverlay = 0;
			if (this.uploadingFiles) {
				return;
			}
			const droppedFiles = [...e.dataTransfer.files];
			this.AddToConversationSelectedFiles(droppedFiles);
		},

		AddToConversationSelectedFiles(newFiles) {
			this.$log.debug("AddToConversationSelectedFiles start");

			// this.checkFileSize(newFiles);

			for (let i = 0; i < newFiles.length; i++) {
				if (newFiles[i].size > 1048576 * this.maxFileSize) {
					this.snackError({
						Text: this.$t("maxFileSizeExceeded", { maxSize: this.maxFileSize }),
					});
				} else {
					this.AddConversationSelectedFiles({
						conversationId: this.ConversationSid,
						file: newFiles[i]
					});
				}
			}
		},

		async visibilityChange(evt, hidden) {
			if (!this.CurrentConversationView.TwilioConversation) {
				return;
			}
			this.isPageVisible = !hidden;
			if (this.isPageVisible && this.messagesView.length > 0) {
				const targetIndex = this.messagesView[this.messagesView.length - 1].index;
				if (this.CurrentConversationView.TwilioConversation.conversation.lastReadMessageIndex === null ||
          this.CurrentConversationView.TwilioConversation.conversation.lastReadMessageIndex < targetIndex) {
					await this.CurrentConversationView.TwilioConversation.conversation.updateLastReadMessageIndex(targetIndex);
					await this.UpdateLastReadMessageOnServer(this.ConversationSid, targetIndex);
					this.$log.debug("updateLastReadMessageIndex [Visibility]: " + targetIndex);
				}
			}
		},

		scrollToMessage(sid) {
			if (document.getElementById(sid)) {
				document.getElementById(sid).scrollIntoView({ behavior: "smooth" });
			}
		},

		scrollToBottom(enableSmooth = true) {
			// DOM is not fully loaded initially, scroll to bottom smooth does not work as expected, so disable smooth for initial loading
			if (this.$refs.scrollArea) {
				this.$refs.scrollArea.scrollTo({
					top: this.$refs.scrollArea.scrollHeight,
					left: 0,
					behavior: enableSmooth ? "smooth" : undefined,
				});
			}
		},

		FetchMessages: async function () {
			// avoid simultaneous fetching
			if (this.loadingMessages) return;

			this.loadingMessages = true;

			try {
				// TODO: fetching all items for now, enable pagination on the server side
				const incomingData = await this.chatService.Get(null, `Messages/${this.currentConversationUserId}`)

				// transform the incoming data to twilio format
				const messagesData = incomingData.map(m => ({
					// conversationSid: m.ConversationSid,
					sid: m.MessageSid,
					body: m.Body,
					author: m.AuthorId,
					index: m.MessageIndex,
					attributes: JSON.parse(m.Attributes),
					timestamp: m.DateCreated,
					liveConversation: m.ConversationSid === this.ConversationSid
				}));

				this.messages.unshift(...messagesData);

				if (messagesData.length > 0) {
					// first item of the last batch, used to load prev messages
					this.firstItemOfLastBatch = messagesData[0].index;

					const targetIndex =
            messagesData[messagesData.length - 1].index;

					if (this.CurrentConversationView.TwilioConversation) {
						if (this.CurrentConversationView.TwilioConversation.conversation.lastReadMessageIndex === null ||
              this.CurrentConversationView.TwilioConversation.conversation.lastReadMessageIndex < targetIndex) {
							await this.CurrentConversationView.TwilioConversation.conversation.updateLastReadMessageIndex(targetIndex);
							await this.UpdateLastReadMessageOnServer(this.ConversationSid, targetIndex);
							this.$log.debug("updateLastReadMessageIndex [Fetch]: " + targetIndex);
						}
					}
				}

				// TODO: enable pagination on the server side
				this.hasPrevPage = false;
			} catch (err) {
				this.snackError({ Text: this.$t("common.error.cannotLoadMessages") });
				this.$captureError(err);
			} finally {
				this.loadingMessages = false;
				this.loadedMessages = true;
			}
		},

		async onScroll(e) {
			const scrollTop = e.target.scrollTop;
			if (scrollTop < 10) {
				await this.LoadPrev();
			}

			this.showScrollButton =
				e.target.scrollTop < e.target.scrollHeight - e.target.offsetHeight - 150;
		},

		async LoadPrev() {
			// TODO: for paging
			if (this.hasPrevPage) {
				const firstMessageSid = this.messages[0].sid;
				await this.FetchMessages();
				this.scrollToMessage(firstMessageSid);
			}
		},

		async SimulateOnMessageAddedCallback(message) {
			const url = "https://localhost:7249/api/v2/Chat/C091A4A5-E083-4D76-A1A1-9ABA0210A031/OnMessageAdded"

			const postContent = {
				ConversationSid: this.ConversationSid,
				Body: message.body,
				ParticipantSid: message.participantSid,
				Author: message.author,
				RetryCount: 0,
				ClientIdentity: "",
				Source: "SDK",
				AccountSid: "",
				MessageSid: message.sid,
				ChatServiceSid: "",
				Index: message.index,
				// DateCreated: message.timestamp.toISOString(),
				Attributes: JSON.stringify(message.attributes),
				EventType: "onMessageAdded",
				MessagingServiceSid: ""
			};

			const keys = Object.keys(postContent);
			this.$log.debug(keys);
			const encodedString = [];
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				// this.$log.debug(encodeURI(postContent[key]));
				encodedString.push(key + "=" + encodeURI(postContent[key]));
			}

			// fetch request with x-www-urlencoded body
			await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded"
				},
				body: encodedString.join("&")
			});
		},

		async UpdateLastReadMessageOnServer(conversationSid, messageIndex) {
			await this.chatService.Post(null, null, `Conversation/${conversationSid}/LastSeenMessage/${messageIndex}`, false)
		},

		async OnMessageAdded(message) {
			this.$log.debug(message.state, "conversation messageAdded");
			// this.$log.error(message.state, message.state);
			this.messages.push({
				...message.state,
				liveConversation: true
			});

			// call this method only in development
			if (process.env.NODE_ENV === "development") {
				if (message.author.toLowerCase() === this.UserProfile.Id.toLowerCase()) {
					await this.SimulateOnMessageAddedCallback(message.state);
				}
			}

			this.CurrentConversationView.LastMessageDate = message.dateUpdated;

			this.$log.debug("setting last message date");
			this.$log.debug(message.dateUpdated);

			// if it's my message, it's already consumed
			if (message.author.toLowerCase() === this.UserProfile.Id.toLowerCase()) {
				await this.CurrentConversationView.TwilioConversation.conversation.updateLastReadMessageIndex(message.index);
				await this.UpdateLastReadMessageOnServer(this.ConversationSid, message.index);
				this.$log.debug("updateLastReadMessageIndex [Me]: " + message.index);
			} else {
				// page is visible, so message considered as read
				if (this.isPageVisible) {
					const targetIndex = message.index;
					if (this.CurrentConversationView.TwilioConversation.conversation.lastReadMessageIndex === null ||
            this.CurrentConversationView.TwilioConversation.conversation.lastReadMessageIndex < targetIndex) {
						await this.CurrentConversationView.TwilioConversation.conversation.updateLastReadMessageIndex(targetIndex);
						await this.UpdateLastReadMessageOnServer(this.ConversationSid, message.index);

						this.$log.debug("updateLastReadMessageIndex [Visible]: " + targetIndex);
					}
				}
			}

			this.SortConversationsByDate();

			this.scrollToBottom();

			this.ShouldDisplayPatientFirstMessageInfo();
		},

		async OnParticipantUpdated({ participant, updateReasons }) {
			this.$log.debug(updateReasons);
			if (participant.identity.toLowerCase() !== this.UserProfile.Id.toLowerCase()) {
				// we want to see the status of the other member
				this.participantLastSeenMessageId = participant.lastReadMessageIndex;
			}
		},

		OnTypingStarted(participant) {
			this.typingParticipant = participant;
			this.$nextTick(() => {
				this.scrollToBottom();
			});
		},

		OnTypingEnded() {
			this.typingParticipant = null;
			this.$nextTick(() => {
				this.scrollToBottom();
			});
		},

		hasMessageFromNutritionistAfterPatient() {
			const currentUserId = this.UserProfile.Id.toLowerCase();

			let pFound = false;

			for (const msg of this.messages) {
				if (pFound && msg.author !== currentUserId) {
					return true;
				}
				if (msg.author === currentUserId) {
					pFound = true;
				}
			}

			return false;
		},

		ShouldDisplayPatientFirstMessageInfo() {
			if (this.isUserInRole("Patient")) {
				const currentUserId = this.UserProfile.Id.toLowerCase();

				if (this.messages.length === 0) {
					this.showPatientFirstMessageInfo = false;
					return;
				}

				// last message from N -> do not show
				if (this.messages[this.messages.length - 1].author !== currentUserId) {
					this.showPatientFirstMessageInfo = false;
					return;
				}

				// check if this case is met:
				// after initial messages.author !== userId
				// there is one or more messages.author === userId

				// if there is any N after P do not show
				if (!this.hasMessageFromNutritionistAfterPatient()) {
					setTimeout(() => {
						this.showPatientFirstMessageInfo = true;
						setTimeout(() => {
							this.scrollToBottom();
						}, 200);
					}, 3000);
				} else {
					this.showPatientFirstMessageInfo = false;
				}
			}
		},

		// the name is UploadFiles, but it is called after message is sent!
		async UploadFiles() {
			const uploadFiles = this.conversationSelectedFiles[this.ConversationSid];

			if (uploadFiles.length === 0) return;

			this.uploadingFiles = true;

			const url = `Conversation/${this.ConversationSid}/file`;

			// prepare uploadQueue
			for (let i = 0; i < uploadFiles.length; i++) {
				this.AddConversationUploadQueue({
					conversationId: this.ConversationSid,
					uploadStatus: {
						name: uploadFiles[i].name,
						progress: 0,
						completed: false,
						cancelSource: this.chatService.CancelTokenSourceForPostWithProgress(),
					},
				});

				uploadFiles[i].queueIndex = this.uploadQueue.length - 1;
			}

			this.$nextTick(() => {
				this.scrollToBottom();
			});

			try {
				// start uploading
				for (let i = 0; i < uploadFiles.length; i++) {
					const formData = new FormData();
					// the name has to be 'files' so that .NET could properly bind it
					formData.append("files", uploadFiles[i]);

					try {
						await this.chatService.PostCustomWithProgress(null, formData, {
							urlPostFix: url,
							onUploadProgress: (e) => {
								const progress = Math.round((100 * e.loaded) / e.total);

								this.SetConversationUploadQueueProgress({
									conversationId: this.ConversationSid,
									uploadItemIndex: uploadFiles[i].queueIndex,
									progress,
								});
							},
							cancelToken:
								this.uploadQueue[uploadFiles[i].queueIndex].cancelSource.token,
						});
					} catch (error) {
						this.$captureError(error);
						// TODO: add file name to error uploadFiles[i].name
						this.snackError({ Text: this.$t("common.error.cannotUploadFile") });
					} finally {
						// set completed on error
						this.SetConversationUploadQueueComplete({
							conversationId: this.ConversationSid,
							uploadItemIndex: uploadFiles[i].queueIndex,
							completed: true,
						});
					}
				}
			} finally {
				this.uploadingFiles = false;
				this.ClearConversationSelectedFiles(this.ConversationSid);
			}
		},
		async InitializeConversation() {
			if (this.ConversationSid) {
				this.InitConversationSelectedFiles(this.ConversationSid);
			}
			this.isPageVisible = !visibility.hidden();

			await this.FetchMessages();

			this.$nextTick(() => {
				this.scrollToBottom(false);
			});
		},

		addListenersToConversation(conversation) {
			this.$log.debug("addListenersToConversation" + conversation.sid);
			conversation.on("messageAdded", this.OnMessageAdded);
			conversation.on("participantUpdated", this.OnParticipantUpdated);
			conversation.on("typingStarted", this.OnTypingStarted);
			conversation.on("typingEnded", this.OnTypingEnded);
		},

		removeListenersFromConversation(conversation) {
			this.$log.debug("removeListenersFromConversation" + conversation.sid);
			conversation.removeListener("messageAdded", this.OnMessageAdded);
			conversation.removeListener(
				"participantUpdated",
				this.OnParticipantUpdated
			);
			conversation.removeListener("typingStarted", this.OnTypingStarted);
			conversation.removeListener("typingEnded", this.OnTypingEnded);
		}
	},

	async mounted() {
		await this.InitializeConversation();
	},
	beforeDestroy() {
		if (!this.listenersUserId) { return; }

		const oldConversationView = this.CombinedConversationView.find((c) => c.UserId === this.listenersUserId);

		if (oldConversationView.TwilioConversation) {
			this.removeListenersFromConversation(oldConversationView.TwilioConversation.conversation);
		}
	},
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";
$headerHeight: 56px;

.chatWindow {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: calc(100vh - 200px);
	@media #{map-get($display-breakpoints, 'xs-only')} {
		height: calc(100vh - #{$headerHeight} * 2);
		height: calc(#{"var(--vh, 1vh) * 100"} - #{$headerHeight * 2});

		-ms-overflow-style: none; // Internet Explorer 10+
		scrollbar-width: none; // Firefox
		&::-webkit-scrollbar {
			display: none; // Safari and Chrome
		}
		.sendButton {
			.v-icon {
				margin: 0;
			}
		}
		.sendFileCard {
			width: 100%;
		}
	}
	#scrollArea {
    max-width: 100%;
		.messageSent,
		.messageReceived {
			display: flex;
			flex-direction: column;
		}
		.messageSent {
			align-items: flex-end;
			.textMessage {
				align-items: flex-end;
				text-align: right;
			}
			.systemMessage {
				.MessageTimestamp {
					align-self: flex-end;
				}
			}
		}
		.messageReceived {
			align-items: flex-start;
			.textMessage {
				align-items: flex-start;
				text-align: left;
			}
			.systemMessage {
				.MessageTimestamp {
					align-self: flex-start;
				}
			}
		}
	}

	& > .v-overlay {
		&--active {
			border: 2px dashed map-get($grey, "base");
		}
	}

		.btn-scrollToBottom {
			top: -50px !important;
			right: 30px !important;
			@media #{map-get($display-breakpoints, 'xs-only')} {
				top: -70px !important;
				right: 10px !important;
			}
		}
}
</style>

<i18n>
{
	"it": {
		"noMessages": "Scrivi direttamente per qualsiasi chiarimento",
		"noMessagesNutritionist": "Scrivi direttamente al paziente per qualsiasi chiarimento",
		"noMessagesPatient": "Scrivi direttamente al nutrizionista per qualsiasi chiarimento",
		"maxFileSizeExceeded": "Uno o più file selezionati superano la dimensione massima di {maxSize} MB"
	}
}
</i18n>
