
<template>
	<v-card class="text-center BookingDateTimeCard">
		<div :class="monthClass" class="poppins-semibold Month">{{MonthAbbreviation}}</div>
		<div class="DayOfMonth poppins-semibold " >{{GetDayOfMonth}}</div>
		<v-divider class="mx-2"></v-divider>
		<div class="DayName poppins-semibold">{{DayName}}</div>
		<v-divider class="mx-2"></v-divider>
		<div class="HourPreposition poppins-semibold grey--text text--darken-1">alle
		</div>
		<div class="Hour poppins-semibold">{{GetHourAndMinute}}</div>
	</v-card>
</template>
<script>
import moment from "moment"

export default {
	name: "BookingDateTimeDisplay",
	props: {
		date: {
			type: String,
			required: true,
		},
		monthClass: {
			type: String,
			required: false,
			default: "info white--text"
		}
	},
	computed: {
		MonthAbbreviation() {
			// get short month name in italian using moment.js
			return moment(this.date).format("MMM");
		},
		DayName() {
			// get day name in italian using moment
			return moment(this.date).format("dddd");
		},
		GetDayOfMonth() {
			// return day of month
			return moment(this.date).format("DD");
		},
		GetHourAndMinute() {
			// return hh:mm
			return moment(this.date).format("HH:mm");
		}
	}
}
</script>
<style lang="scss" scoped>
.BookingDateTimeCard {
  min-width: 80px;
  max-width: 95px;

  .Month {
    font-size: 0.8rem;
  }

  .DayOfMonth {
    font-size: 1.8rem;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .DayName{
    font-size: 0.8rem;
  }

  .HourPreposition{
    font-size: 0.6rem; line-height: 0.8rem;
    padding-top:8px;
  }

  .Hour{
    font-size: 1rem;
    padding-bottom:8px;
  }

  // Custom styles for xs screens (Vuetify xs: <600px)
  @media (max-width: 599px) {
    min-width: 55px;
    max-width: 65px;

    .Month {
      font-size: 0.7rem;
    }

    .DayOfMonth {
      font-size: 1.4rem;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .DayName {
      font-size: 0.6rem;
    }

    .HourPreposition {
      font-size: 0.6rem;
      line-height: 0.7rem;
      padding-top:3px;
    }

    .Hour {
      font-size: 0.85rem;
      padding-bottom:3px;
    }
  }
}

</style>
