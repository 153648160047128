<template>
	<v-card class="mb-2">
		<v-card-text>
			<span class="text-overline">{{ item.WebhookId }} ricevuto il
				{{ item.CreateDate | formatLocalDateTime }}</span><br />
			<h4 class="text-h5">
				{{ item.Name }} / {{ item.Email | emptyField }}

				<v-icon :color="item.EmailVerified ? 'success' : 'grey lighten-1'">fas fa-check-circle</v-icon>

				<v-chip dark color="error" class="ml-3" v-if="item.UserPreviouslyCreated ">
					utente già esiste
				</v-chip><v-chip dark color="warning" class="ml-3" v-if="item.IgnoreRequest">
					<v-icon left small> fas fa-ban</v-icon>scartata
				</v-chip>
			</h4>

			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-header>Dettagli </v-expansion-panel-header>
					<v-expansion-panel-content>
						<TallyFormViewer :AdminView="true" :TallyForm="item.webhookData"></TallyFormViewer>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
      <div class="mt-3" v-if="!item.ApplicationUser">
        <v-btn v-if="!showEditEmailCard" @click="showEditEmailCard=1; editedEmail=item.Email"
               class="mr-2 text-transform-none">
          <v-icon left>fas fa-edit</v-icon>Modifica l'email
        </v-btn>
        <v-card v-if="showEditEmailCard">
          <v-card-text>
            <v-text-field type="email" :label=" $t('common.email')" v-model="editedEmail"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="grey darken-2" @click="showEditEmailCard=0" :loading="updatingEmail">
              <v-icon >fas fa-times-circle</v-icon>{{ $t("common.cancel")}}
            </v-btn>
            <v-btn color="primary" @click="updateTallyEmail" :loading="updatingEmail">
              <v-icon >fas fa-update</v-icon> {{ $t("common.save")}}
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-btn @click="ResendVerificationCode"
           :loading="sendingVerificationMail"
           class="mr-2 text-transform-none">
          <v-icon left>far fa-paper-plane</v-icon>
          Invia mail di verifica
        </v-btn>

        <v-btn class="mr-2 text-transform-none" @click="copyVerificationLinkToClipboard">
          <v-icon left>fas fa-copy</v-icon>
          Copia Link di verifica</v-btn>

        <v-btn @click="VerifyManually"
               :loading="verifyingManually"
               color="warning"  class="mr-2 text-transform-none">
          <v-icon left>fas fa-check</v-icon>
          Verifica Manuale</v-btn>
    </div>
		</v-card-text>
		<v-card-actions>
			<v-btn color="error" text
          @click="deleteRequest" :loading="deletingRequest"><v-icon left>fas
          fa-trash</v-icon>{{ $t("common.delete") }}</v-btn>
			<v-spacer></v-spacer>
      <v-btn color="nyGreen" @click="ShowMatchingResults">Match</v-btn>
      <v-btn color="nyYellow" dark @click="MatchDebug"
      ><v-icon left>fas fa-filter</v-icon>
        Match Funnel
      </v-btn>

      <v-dialog v-model="ShowMatchDebugDialog" max-width="600"  scrollable>
        <v-card>
          <v-card-text>
            <div v-for="match in MatchDebugResults" :key="match.Step" class="poppins-regular mb-2">
              <h3>{{ match.Step}}</h3>
              <div v-for="s in match.FilteredNutritionists	" :key="s">{{s}}</div>
            </div>
          </v-card-text>
          <v-card-actions><v-btn @click="ShowMatchDebugDialog = false">{{ $t("common.close")}}</v-btn></v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showMatchingResultsDialog" max-width="600"  scrollable>
        <v-card>
          <v-card-title>Match</v-card-title>
          <v-card-text class="pt-2">
            <div v-for="match in matchingResults" :key="match.UserId" class="poppins-regular mb-2">
             <h3>{{match.MatchingResult.Profile.DisplayName}}</h3>
              RequestedSkillCount: {{match.MatchingResult.RequestedSkillCount}} /
              MatchingSkillCount: {{match.MatchingResult.MatchingSkillCount}} <br/>
              RequestedSlotCount: {{match.MatchingResult.RequestedSlotCount}} /
              MatchingSlotsCount: {{match.MatchingResult.MatchingSlotsCount}} <br/>
              PriorityPoints: {{match.MatchingResult.PriorityPoints}} /
              RecentAssignmentsPoints: {{match.MatchingResult.RecentAssignmentsPoints}} <br/>
              <span class="poppins-semibold">TotalPoints: {{match.MatchingResult.TotalPoints}}</span>

              <div v-if="match.UnavailableDatesInterval.length > 0">
                <h4>Date non disponibile</h4>
                <div v-for="(date, i) in match.UnavailableDatesInterval" :key="i">
                  {{ date.Start }} - {{ date.End }}
                </div>
              </div>
            </div>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="showMatchingResultsDialog = false">{{ $t("common.close")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

			<v-btn color="warning" text v-if="!item.IgnoreRequest"
           @click="discardRequest"
           :loading="discardingRequest"><v-icon left>fas fa-ban</v-icon>{{ $t("discard") }}
      </v-btn>
			<ClientAccountPicker class="mr-3" style="max-width: 240px" RoleName="Nutritionist"
				:label="$t('nutriyou.nutritionist')" v-model="nutritionistUserId" v-if="
					item.ApplicationUser &&
					!item.ApplicationUser.Patient.DefaultNutritionistUserId
				"></ClientAccountPicker>
			<v-chip dark color="secondary" v-if="
					item.ApplicationUser &&
					item.ApplicationUser.Patient.DefaultNutritionistUserId
				">
				Assegnata a
				<span class="mx-2 font-weight-bold">{{
					item.Nutritionist.DisplayName
					}}</span>
			</v-chip>

			<v-btn color="primary" v-if="
					item.ApplicationUser &&
					!item.ApplicationUser.Patient.DefaultNutritionistUserId
				" @click="assignNutritionist" :loading="assigningToNutritionist">Assign</v-btn>

			<v-btn v-if="item.CreatedPatientUserId" color="primary" icon
				:to="'/Users/EditUser/' + item.CreatedPatientUserId"><v-icon >fas fa-user</v-icon></v-btn>

      <v-btn v-if="item.CreatedPatientUserId" color="primary" icon
             :to="`/Admin/Patients/${item.CreatedPatientUserId}/Events`"><v-icon>fas fa-history</v-icon></v-btn>

			<confirm ref="confirm"></confirm>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions } from "vuex";
import TallyFormViewer from "./TallyFormViewer.vue";
import CrudClient from "@/services/CrudClient/";
import ClientAccountPicker from "@/components/Account/ClientAccountPicker";
import ConfirmTool from "@/components/Shared/Common/Confirm";

export default {
	components: {
		TallyFormViewer,
		ClientAccountPicker,
		confirm: ConfirmTool,
	},
	data: function () {
		return {
			updatingEmail: false,
			nutritionistUserId: null,
			showMatchingResultsDialog: false,
			matchingResults: null,
			showEditEmailCard: false,
			editedEmail: null,
			deletingRequest: false,
			discardingRequest: false,
			assigningToNutritionist: false,
			sendingVerificationMail: false,
			verifyingManually: false,

			MatchDebugResults: [],
			ShowMatchDebugDialog: false
		};
	},
	props: {
		item: { type: Object, required: true },
	},
	created() {
		this.TallyWebhookService = new CrudClient("TallyWebhook");
		this.NutritionistsService = new CrudClient("Nutritionists");
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async discardRequest() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("discard"),
					"Stai per scartare la richiesta",
					{ color: "warning" }
				))
			) {
				return;
			}

			try {
				this.discardingRequest = true;
				await this.TallyWebhookService.Patch(
					this.item.WebhookId,
					{ IgnoreRequest: true },
					false);

				this.snackSuccess({ Text: "Richiesta scartata!" });

				this.$emit("userCreated");
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: "Impossibile scartare la richiesta" });
			} finally {
				this.discardingRequest = false;
			}
		},

		async deleteRequest() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					"Stai per cancellare la richiesta",
					{ color: "error" }
				))
			) {
				return;
			}

			try {
				this.deletingRequest = true;
				await this.TallyWebhookService.Delete(
					this.item.WebhookId);

				this.snackSuccess({ Text: "Richiesta cancellata!" });

				this.$emit("userCreated");
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: "Impossibile cancellare la richiesta" });
			} finally {
				this.deletingRequest = false;
			}
		},

		async updateTallyEmail() {
			if (
				!(await this.$refs.confirm.open(
					"Modifica l'Email",
					"Stai per modificare la mail",
					{ color: "warning" }
				))
			) {
				return;
			}

			try {
				this.updatingEmail = true;

				await this.TallyWebhookService.Patch(
					this.item.WebhookId,
					{ Email: this.editedEmail },
					false);

				this.snackSuccess({ Text: "Email aggiornata!" });

				this.$emit("userCreated");
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: "Impossibile aggiornare la mail" });
			} finally {
				this.updatingEmail = false;
			}
		},

		async assignNutritionist() {
			if (!this.nutritionistUserId) {
				this.snackError({ Text: "Seleziona Nutrizionista!" });

				return;
			}

			try {
				this.assigningToNutritionist = true;

				const selectedNutritionist = await this.NutritionistsService.Get(
					this.nutritionistUserId
				);

				if (
					!(await this.$refs.confirm.open(
						this.$t("Assegna Nutrizionista"),
						`Stai per assegnare  [${selectedNutritionist.ApplicationUser.DisplayName}] all'utente [${this.item.Name}]`,
						{ color: "warning" }
					))
				) {
					return;
				}

				await this.TallyWebhookService.Post(
					this.item.WebhookId,
					{
						NutritionistUserId: this.nutritionistUserId,
					},
					"AssignNutritionist"
				);
				this.snackSuccess({ Text: "Nutrizionista assegnato!" });
				// this.$log.debug(result);

				this.$emit("userCreated");
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: "Impossibile creare l'utente" });
			} finally {
				this.assigningToNutritionist = false;
			}
		},

		async ResendVerificationCode() {
			if (
				!(await this.$refs.confirm.open(
					"Invia la mail di verifica",
					"Stai per inviare al paziente la mail di verifica",
					{ color: "warning" }
				))
			) {
				return;
			}

			try {
				this.sendingVerificationMail = true;
				await this.TallyWebhookService.Post(
					this.item.WebhookId,
					null,
					"ResendVerificationMail");

				this.snackSuccess({ Text: "Email inviata!" });

				this.$emit("userCreated");
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: "Impossibile inviare la mail" });
			} finally {
				this.sendingVerificationMail = false;
			}
		},
		async VerifyManually() {
			if (
				!(await this.$refs.confirm.open(
					"Verifica la mail",
					"Stai per verificare la mail, di seguito vienne creato l'utente e assegnato il nutrizionista",
					{ color: "error" }
				))
			) {
				return;
			}

			try {
				this.verifyingManually = true;
				await this.TallyWebhookService.Post(
					this.item.WebhookId,
					null,
					"VerifyManually");

				this.snackSuccess({ Text: "Utente creato!" });

				this.$emit("userCreated");
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: "Impossibile completare l'operazione" });
			} finally {
				this.verifyingManually = false;
			}
		},

		async copyVerificationLinkToClipboard() {
			const domain = process.env.NODE_ENV === "development" ? "http://localhost:8080/" : "https://www.nutriyou.it/";

			const link = domain + "VerifyEmail/" + this.item.VerificationToken;

			try {
				await navigator.clipboard.writeText(link);
				this.snackSuccess({ Text: "Link copiato negli appunti!" });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: "Impossibile copiare il link" });
			}
		},

		async ShowMatchingResults() {
			this.showMatchingResultsDialog = true;
			this.matchingResults = [];
			this.matchingResults = await this.TallyWebhookService.Get(this.item.WebhookId, "MatchingResults");
		},

		async MatchDebug() {
			this.MatchDebugResults = await this.TallyWebhookService.Get(this.item.WebhookId, "MatchingDebug");

			this.ShowMatchDebugDialog = true;
		}
	},
};
</script>
<style>
</style>
<i18n>
	{
		"it": {
			"discard": "scarta"
		}
	}
</i18n>
