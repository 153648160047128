<template>
<v-btn v-bind="$attrs"
       v-on="$listeners" >
  <v-icon :left="$vuetify.breakpoint.smAndUp"
          :size="$vuetify.breakpoint.xs ? 18 : ''"
          v-if="leftIcon">{{ icon }}</v-icon>
  <span v-if="$vuetify.breakpoint.smAndUp"> {{ text }}</span>

  <v-icon  :right="$vuetify.breakpoint.smAndUp"
           :size="$vuetify.breakpoint.xs ? 18 : ''"
           v-if="!leftIcon">{{ icon }}</v-icon>
</v-btn>
</template>
<script>
export default {
	name: "v-btn-hide-text",
	props: {
		text: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: true,
		},
		leftIcon: {
			type: Boolean,
			required: false,
			default: true // Default state
		}
	}
}
</script>
