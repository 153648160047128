<template>
  <v-card color="nyGreenLight">
    <v-card-text>
      <v-container fluid>
        <v-row >
          <v-col cols="3" class="ma-0 pa-0">
            <div class="justify-center mb-2">
              <v-img :src="NutritionistProfile.ImageFileUrl" max-width="120"
                     class="white rounded"></v-img>
            </div>
          </v-col>
          <v-col cols="9">
            <div class="TitleFont nyGreenAlt--text text--darken-2 mb-1 CardTitleSize">
              {{ NutritionistProfile.ApplicationUser.DisplayName }}
            </div>

            <div class="poppins-medium" style="font-size: 1rem;">
              {{ NutritionistProfile.ProfessionalTitle}}
            </div>
          </v-col>
        </v-row>
      </v-container>
      <template v-if="!isExpanded">
        <div
          style="display: -webkit-box; -webkit-line-clamp: 6; -webkit-box-orient: vertical; overflow: hidden;">
          {{ NutritionistProfile.Bio }}
        </div>
        <h3 class="nutriyou-h3 mt-5 nyGreenAlt--text text--darken-2" style="font-size: 1.1rem;">
          Ambiti di specializzazione
        </h3>
        <div class="mb-3">
          <div v-for="(skill, i) in matchingSkills" :key="i">
            <v-icon class="nyGreenAlt--text text--darken-2 mr-2" small>fas fa-check</v-icon> {{skill}}
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <p v-for="part in BioParts" :key="part" class="poppins-regular">
            {{ part }}
          </p>
        </div>
        <div>
          <h2 class="nutriyou-h3 nyrose--text mb-2" style="font-size: 1.1rem;">Formazione</h2>
          <div v-for="part in TrainingParts" :key="part" class="poppins-regular">
            <template v-if="!part.startsWith('Iscrizione ') && !part.startsWith('Esercita')">
              <v-icon class="nyrose--text mr-2" small>fas fa-check</v-icon>
              {{ part }}
            </template>
            <template v-else>
              <div class="poppins-bold grey--text text--darken-2 mt-2">
                {{ part }}
              </div>
            </template>
          </div>
        </div>

        <div class="poppins-regular">
          <h3 class="nutriyou-h3 mt-5 nyGreenAlt--text text--darken-2 mb-2" style="font-size: 1.1rem;">
            Ambiti di specializzazione
          </h3>
          <div v-for="tag in nutritionistTags" :key="tag.UserSkillId">
            <v-icon class="nyGreenAlt--text text--darken-2 mr-2" small>fas fa-check</v-icon>
            {{ tag.Skill.Name }}
          </div>
        </div>
      </template>

      <div class="mb-2 mt-2">
        <v-btn v-if="!isExpanded"
               color="nyGreenAlt darken-2"
               class="text-transform-none"

               outlined
               dark block large
               @click="isExpanded = true">
         {{ NutritionistProfile.Gender === 1 ? "Conoscilo meglio" : "Conoscila meglio"}}
          <v-icon right>fas fa-angle-down</v-icon>
        </v-btn>
        <v-btn v-else @click="isExpanded = false"
               color="nyGreenAlt darken-2"
               class="text-transform-none"
               outlined
               dark block large>Minimizza
            <v-icon right>fas fa-angle-up</v-icon>
        </v-btn>
      </div>
      <div>
        <slot name="mail-button">
          <v-btn color="nyGreenAlt darken-2"
                 class="text-transform-none"
                 dark block large
                 to="/Chat">
            <v-badge color="red" v-if="TotalNewMessages > 0" overlap>
              <template v-slot:badge>
                <span>{{ TotalNewMessages }}</span>
              </template>
              <v-icon color="white" left>fas fa-fw fa-comment-alt-dots</v-icon>
            </v-badge>
            <v-icon color="white" left v-else>fas fa-fw fa-comment-alt-dots</v-icon>
            <span :class="{ 'ml-3': TotalNewMessages}">
              {{ TotalNewMessages > 0 ?
              (NutritionistProfile.Gender === 1 ? "Rispondi al tuo nutrizionista" : "Rispondi alla tua nutrizionista" ):
              (NutritionistProfile.Gender === 1 ? "Scrivi al tuo nutrizionista" : "Scrivi alla tua nutrizionista")
               }}</span>
          </v-btn>
        </slot>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	name: "NutritionistProfileExpandableCard",
	props: {
		NutritionistProfile: {
			type: Object
		},
		matchingSkills: {
			type: Array
		},
		nutritionistTags: {
			type: Array
		}
	},
	data() {
		return {
			isExpanded: false
		}
	},
	computed: {
		...mapGetters([
			"TotalNewMessages"]),

		TrainingParts() {
			if (!this.NutritionistProfile) return null;
			return this.NutritionistProfile.Training.split(/\r?\n/).filter((i) => i);
		},

		BioParts() {
			if (!this.NutritionistProfile) return null;
			return this.NutritionistProfile.Bio.split(/\r?\n/).filter((i) => i);
		},
	},
}
</script>

<style scoped lang="scss">

</style>
